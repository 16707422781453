import React from 'react'
import momentTimeZone from 'moment-timezone'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

interface DataSourceType {
  customerNote: string
  tourAgentName: string
  propertyAddress: string
  prospectPhone: string
  tourType: string
  startTime: string
  timeZoneText: string
  timeZone: string
  cancelReason: string
  cancelNote: string
  status: string
}

interface Props {
  dataSource: DataSourceType
  loading: boolean
}

const TourCancelBaseInfoCard = ({ dataSource, loading }: Props) => {
  const { customerNote, cancelReason, tourAgentName, propertyAddress, tourType, startTime, timeZoneText, timeZone, cancelNote, status } =
    dataSource || {}
  if (loading) {
    return (
      <Box>
        <Skeleton height={40} />
      </Box>
    )
  }
  return (
    <div
      className={`tour-cancel-base-info rounded-[32px] px-[40px] pt-[8px] pb-[40px] bg-white ${status === 'CANCELED' ? 'block' : 'hidden'}`}
      style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.04)' }}
    >
      <div className="border-b border-b-[#E0E2EF] mt-[32px]">
        <p className="text-gray-550 text-[28px] font-normal mb-[8px]">Tour host</p>
        <p className="font-semibold text-[32px] mb-[32px]">{tourAgentName}</p>
      </div>
      <div className="border-b border-b-[#E0E2EF] mt-[32px]">
        <p className="text-gray-550 text-[28px] font-normal mb-[8px]">Address</p>
        <p className="font-semibold text-[32px] mb-[32px]">{propertyAddress || '-'}</p>
      </div>
      <div className="border-b border-b-[#E0E2EF] mt-[32px]">
        <p className="text-gray-550 text-[28px] font-normal mb-[8px]">Date & time</p>
        <p className="font-semibold text-[32px] mb-[32px]">
          {startTime ? momentTimeZone(startTime).tz(timeZone).format('h:mm a, dddd, MMM DD, YYYY') : '-'}
          {startTime && timeZoneText ? timeZoneText : null}
        </p>
      </div>
      <div className="border-b border-b-[#E0E2EF] mt-[32px]">
        <p className="text-gray-550 text-[28px] font-normal mb-[8px]">Tour option</p>
        <p className="font-semibold text-[32px] mb-[32px]">{tourType || '-'}</p>
      </div>
      {customerNote && (
        <div className="border-b border-b-[#E0E2EF] mt-[32px]">
          <p className="text-gray-550 text-[28px] font-normal mb-[8px]">Additional notes</p>
          <p className="font-semibold text-[32px] mb-[32px]">{customerNote}</p>
        </div>
      )}
      <div className="mt-[32px]">
        <p className="text-gray-550 text-[28px] font-normal mb-[8px]">Cancellation reason</p>
        <p className="font-semibold text-[32px]">{cancelReason || '-'}</p>
        <Typography className="!text-[28px] font-normal !text-[#75798D] !font-Averta6">{cancelNote || ''}</Typography>
      </div>
    </div>
  )
}

export default TourCancelBaseInfoCard
