/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash'
import { ScheduleTourPropertyType, SelectedUnitsType } from './contantsAndType'

export const calcSelectedCount = (units: SelectedUnitsType) => {
  let count = 0
  units.forEach(item => {
    const onlySelectedFloorPlan = item.unitsInfo.units.length === 0 && item.otherUnit.floorPlanIndex === -1
    if (onlySelectedFloorPlan) {
      count += 1
    }
    if (item.unitsInfo.units.length > 0) {
      count += item.unitsInfo.units.length
    }
    if (item.otherUnit.floorPlanIndex !== -1) {
      count += 1
    }
  })
  return count
}

// 如果只选了floorplan的 把所有该floorplan的unit都填充进去
export const fillOnlySelectFloorplanSelectedUnits = (
  selectedUnits: SelectedUnitsType,
  selectProperty: ScheduleTourPropertyType | undefined
) => {
  const tempSelectedUnits = cloneDeep(selectedUnits)
  if (!selectProperty) return tempSelectedUnits
  tempSelectedUnits.forEach(item => {
    if (item.unitsInfo.units.length === 0) {
      const floorPlan = selectProperty.floorPlanModeUnit.find(f => f.floorPlanIndex === item.unitsInfo.floorPlanIndex)
      if (floorPlan) {
        let earlyStartTime = Infinity
        let earlyUnit = null
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < floorPlan.availableUnits.length; index++) {
          const unit = floorPlan.availableUnits[index]
          if (unit.availableStatus === 0) {
            earlyUnit = unit
            break
          }
          if (unit.availableStatus === 1) {
            if (unit.availableStartTime < earlyStartTime) {
              earlyStartTime = unit.availableStartTime
              earlyUnit = unit
            }
          }
          if (unit.availableStatus === 2) {
            if (unit.preTourStartDate < earlyStartTime) {
              earlyStartTime = unit.preTourStartDate
              earlyUnit = unit
            }
          }
        }
        if (earlyUnit) {
          const { name, id, availableStatus, availableStartTime, preTourEndDate, preTourStartDate } = earlyUnit
          item.unitsInfo.units.push({
            name,
            id: Number(id),
            availableStatus,
            availableStartTime,
            preTourStartDate,
            preTourEndDate
          })
        }
      }
    }
  })
  return tempSelectedUnits
}

export const calcTimeOverlap = (selectedUnits: SelectedUnitsType) => {
  let min = -Infinity
  let max = Infinity
  let isOverlap = true
  selectedUnits.forEach(item => {
    item.unitsInfo.units.forEach(unit => {
      if (unit.availableStatus === 1) {
        if (unit.availableStartTime > max) {
          isOverlap = false
          return
        }
        if (unit.availableStartTime > min) {
          min = unit.availableStartTime
        }
      } else if (unit.availableStatus === 2) {
        if (unit.preTourStartDate > max || unit.preTourEndDate < min) {
          isOverlap = false
          return
        }
        if (unit.preTourStartDate >= min) {
          min = unit.preTourStartDate
          max = Math.min(max, unit.preTourEndDate)
        }
        if (unit.preTourStartDate < min) {
          max = Math.min(max, unit.preTourEndDate)
        }
      }
    })
  })
  return { isOverlap, min, max }
}

export const refactSelectedUnitTime = (properties: ScheduleTourPropertyType[]) => {
  const cloneProperties = cloneDeep(properties)
  cloneProperties.forEach(property => {
    if (property.floorPlanModeUnit.length > 0) {
      property.floorPlanModeUnit.forEach(floorPlan => {
        const unitEarliestTime = { min: Infinity, max: -Infinity }
        floorPlan.availableUnits.forEach(unit => {
          if (unit.availableStatus === 1 && unit.availableStartTime < unitEarliestTime.min) {
            unitEarliestTime.min = unit.availableStartTime
            unitEarliestTime.max = -Infinity
          } else if (unit.availableStatus === 2 && unit.preTourStartDate < unitEarliestTime.min) {
            unitEarliestTime.min = unit.preTourStartDate
            unitEarliestTime.max = unit.preTourEndDate
          }
        })
        if (unitEarliestTime.min !== Infinity) {
          floorPlan.availableUnits.forEach(unit => {
            if (unit.availableStatus !== 0) {
              if (unitEarliestTime.max === -Infinity) {
                unit.availableStatus = 1
                unit.availableStartTime = unitEarliestTime.min
              } else {
                unit.availableStatus = 2
                unit.preTourStartDate = unitEarliestTime.min
                unit.preTourEndDate = unitEarliestTime.max
              }
            }
          })
        }
      })
    }
  })
  return cloneProperties
}

export const getStatsigProperties = (properties: ScheduleTourPropertyType[]) => {
  const statsigProperties = properties.map(property => {
    const floorPlanModeUnit = property.floorPlanModeUnit.map(floorPlan => {
      const availableUnits = floorPlan.availableUnits.map(unit => ({
        id: unit.id,
        name: unit.name
      }))
      return {
        floorPlanName: floorPlan.floorPlanName,
        floorUrl: floorPlan.floorUrl,
        availableUnits
      }
    })
    return {
      id: property.id,
      name: property.name,
      aliasName: property.aliasName,
      floorPlanModeUnit
    }
  })
  return statsigProperties
}

export const buildTourApartmentsEventMetadata = (
  prospectId: string,
  currentProperty?: ScheduleTourPropertyType,
  selectedUnitMap?: SelectedUnitsType
) => {
  if (!currentProperty || !selectedUnitMap) return {}

  const selectedUnitList: unknown[] = []
  selectedUnitMap.forEach(item => {
    if (item.unitsInfo.floorPlanIndex !== -1) {
      if (item.unitsInfo.units.length > 0) {
        item.unitsInfo.units.map(unit =>
          selectedUnitList.push({
            floorPlanName: item.unitsInfo.floorPlanTitle,
            unitId: unit.id,
            unitName: unit.name
          })
        )
      } else {
        selectedUnitList.push({
          floorPlanName: item.unitsInfo.floorPlanTitle
        })
      }
    }
  })

  return {
    prospectId,
    propertyId: String(currentProperty.id),
    propertyName: currentProperty.name,
    numberOfFloorPlansShown: String(currentProperty?.floorPlanModeUnit?.length ?? 0),
    numberOfUnitsShown: String(
      currentProperty?.floorPlanModeUnit?.reduce((acc, floorModeUnit) => acc + floorModeUnit.availableUnits.length, 0) ?? 0
    ),
    selectedUnits: JSON.stringify(selectedUnitList)
  }
}

export const getFloorPlanName = (floorPlan: { floorPlanBedroomNum: string; floorPlanBathroomNum: string; floorPlanName: string }) => {
  const { floorPlanBedroomNum, floorPlanBathroomNum, floorPlanName } = floorPlan

  const planName = floorPlanName ? ` - ${floorPlanName}` : ''
  if (Number(floorPlanBedroomNum) === 0) {
    return `Studio${planName}`
  }
  return `${Number(floorPlanBedroomNum) || ''} Bed ${Number(floorPlanBathroomNum) || ''} Bath${planName || ''}`
}

export default {}
