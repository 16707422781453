import React from 'react'

const CalendarIcon = () => (
  <svg width="75" height="74" viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Request">
      <path id="Vector" d="M70.917 8.83789H8.25037V58.8379H70.917V8.83789Z" fill="#E0E2EF" />
      <rect
        id="Rectangle 21342"
        x="18.332"
        y="13.9434"
        width="57.8268"
        height="45.0828"
        transform="rotate(15 18.332 13.9434)"
        fill="#EFF1FA"
      />
      <rect
        id="Rectangle 21355"
        x="23.919"
        y="29.5059"
        width="9.33333"
        height="6.66667"
        transform="rotate(15 23.919 29.5059)"
        fill="white"
      />
      <rect
        id="Rectangle 21356"
        x="38.1658"
        y="33.3008"
        width="9.33333"
        height="6.66667"
        transform="rotate(15 38.1658 33.3008)"
        fill="white"
      />
      <rect
        id="Rectangle 21358"
        x="48.5857"
        y="50.1719"
        width="9.33333"
        height="6.66667"
        transform="rotate(15 48.5857 50.1719)"
        fill="white"
      />
      <rect
        id="Rectangle 21357"
        x="52.4125"
        y="37.0977"
        width="9.33333"
        height="6.66667"
        transform="rotate(15 52.4125 37.0977)"
        fill="#2A61FF"
      />
      <path
        id="Vector_2"
        d="M27.9478 14.8998C27.0068 14.8997 26.0776 14.691 25.2269 14.2886C24.3763 13.8862 23.6255 13.3003 23.0285 12.5729C22.4315 11.8455 22.0033 10.9948 21.7746 10.082C21.5459 9.16922 21.5224 8.21708 21.7059 7.29415C21.9514 6.05961 22.5575 4.92562 23.4475 4.03561C24.3376 3.1456 25.4716 2.53957 26.7062 2.29417C27.6291 2.11058 28.5813 2.13394 29.4941 2.36258C30.4069 2.59121 31.2576 3.01942 31.985 3.61638C32.7125 4.21334 33.2984 4.96418 33.7008 5.81483C34.1031 6.66548 34.3118 7.59477 34.3119 8.53577V8.79047M51.0785 14.8998C50.1375 14.8997 49.2083 14.691 48.3577 14.2886C47.507 13.8862 46.7562 13.3003 46.1592 12.5729C45.5623 11.8455 45.134 10.9948 44.9053 10.082C44.6766 9.16922 44.6531 8.21708 44.8366 7.29415C45.0821 6.05961 45.6882 4.92562 46.5783 4.03561C47.4683 3.1456 48.6024 2.53957 49.8369 2.29417C50.7598 2.11058 51.712 2.13394 52.6248 2.36258C53.5376 2.59121 54.3883 3.01942 55.1158 3.61638C55.8432 4.21334 56.4292 4.96418 56.8315 5.81483C57.2339 6.66548 57.4426 7.59477 57.4426 8.53577V8.79047"
        stroke="#050A22"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <circle id="Ellipse 1056" cx="14.8116" cy="59.8301" r="14" fill="#FF3640" />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5291 43.0332C9.79706 43.0332 3.52905 49.3012 3.52905 57.0332C3.52905 64.7652 9.79706 71.0332 17.5291 71.0332C25.261 71.0332 31.5291 64.7652 31.5291 57.0332C31.5291 49.3012 25.261 43.0332 17.5291 43.0332ZM7.52905 57.0332C7.52905 51.5104 12.0062 47.0332 17.5291 47.0332C23.0519 47.0332 27.5291 51.5104 27.5291 57.0332C27.5291 62.5561 23.0519 67.0332 17.5291 67.0332C12.0062 67.0332 7.52905 62.5561 7.52905 57.0332ZM19.5294 53.0334L19.5297 51.0334L15.5297 51.033L15.5294 53.033L15.5291 56.9728L15.529 57.8053L16.1196 58.392L18.9203 61.1738L20.3393 62.5833L23.1582 59.7453L21.7392 58.3359L19.5291 56.1407L19.5294 53.0334Z"
        fill="#050A22"
      />
    </g>
  </svg>
)
export default CalendarIcon
