import React, { SvgIcon, SvgIconProps } from '@mui/material'

export default function DownIcon(props: SvgIconProps) {
  const { color } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path
        d="M17.2902 9.29055C16.9002 8.90055 16.2702 8.90055 15.8802 9.29055L12.0002 13.1705L8.12022 9.29055C7.73022 8.90055 7.10022 8.90055 6.71022 9.29055C6.32022 9.68055 6.32022 10.3105 6.71022 10.7005L11.3002 15.2905C11.6902 15.6805 12.3202 15.6805 12.7102 15.2905L17.3002 10.7005C17.6802 10.3205 17.6802 9.68055 17.2902 9.29055Z"
        fill={color ?? '#050A22'}
      />
    </SvgIcon>
  )
}
