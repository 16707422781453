import React from 'react'

const DateIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M0.333252 6.99967H13.6666V12.9997C13.6666 13.1765 13.5963 13.3461 13.4713 13.4711C13.3463 13.5961 13.1767 13.6663 12.9999 13.6663H0.999919C0.823108 13.6663 0.653538 13.5961 0.528514 13.4711C0.40349 13.3461 0.333252 13.1765 0.333252 12.9997V6.99967ZM10.3333 1.66634H12.9999C13.1767 1.66634 13.3463 1.73658 13.4713 1.8616C13.5963 1.98663 13.6666 2.1562 13.6666 2.33301V5.66634H0.333252V2.33301C0.333252 2.1562 0.40349 1.98663 0.528514 1.8616C0.653538 1.73658 0.823108 1.66634 0.999919 1.66634H3.66659V0.333008H4.99992V1.66634H8.99992V0.333008H10.3333V1.66634Z"
      fill="#0D1333"
    />
  </svg>
)
export default DateIcon
