import React from 'react'
import { Box, Checkbox, Chip, Typography } from '@mui/material'
import { CommonModuleProps, QuestionItemProps, MODULES_CODE_ENUM } from './types.d'
import indexHooks from './hooks/index'

const { creditScoreHooks } = indexHooks
const guaranteeList = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
]

const CreditScore = ({ currentIndex }: CommonModuleProps) => {
  const {
    scoreList,
    showChildrenKey,
    currentData,
    creditScoreType,
    isStudent,
    cosigner,
    onChangeScore,
    onChangeIsStudent,
    onChangeGuarantee,
    isPc = false
  } = creditScoreHooks(currentIndex)

  return (
    <>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[16px]">
        {isPc ? (
          <div className="w-full grid gap-y-[32px]">
            {scoreList.map((score: QuestionItemProps) => (
              <Box
                onClick={() => onChangeScore(score.key)}
                className={`${
                  score.key === creditScoreType ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                } flex items-center cursor-pointer justify-start border rounded-[24px] p-[32px]`}
                key={score.key}
              >
                <Box
                  className={`w-[32px] h-[32px] border rounded-[32px] mr-[32px] ${
                    score.key === creditScoreType ? 'border-[#E74F4F] border-[12px]' : 'border-[#E0E2EF] '
                  }`}
                />
                <Typography className="!font-Averta4 !text-[28px] !leading-[48px]">{score.desc}</Typography>
              </Box>
            ))}
          </div>
        ) : (
          <div className="w-full grid gap-y-[24px]">
            {scoreList.map((score: QuestionItemProps) => (
              <Chip
                onClick={() => onChangeScore(score.key)}
                className={`${
                  score.key === creditScoreType ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                } !font-Averta4 !text-[28px] !h-[72px]`}
                label={<span className="font-Averta4 text-[28px] leading-[48px]">{score.desc}</span>}
                key={score.key}
                variant="outlined"
              />
            ))}
          </div>
        )}
      </Box>
      {showChildrenKey?.includes(MODULES_CODE_ENUM?.IS_STUDENT) && (
        <Box className="flex items-center !px-[40px]">
          <Checkbox
            sx={{
              color: '#E0E2EF',
              padding: '0',
              '&.Mui-checked': {
                color: '#050A22'
              },
              '& svg': {
                fontSize: '24px'
              }
            }}
            defaultChecked={isStudent}
            onChange={() => {
              onChangeIsStudent(!isStudent)
            }}
          />
          &nbsp;&nbsp;
          <span className="font-Averta4 text-[28px]">{currentData?.question?.isStudent?.desc}</span>
        </Box>
      )}
      {showChildrenKey?.includes(MODULES_CODE_ENUM?.COSIGNER) && !isStudent && (
        <>
          <Box className="w-full !px-[40px] !py-[32px]">
            <Typography className="!font-Averta6 !text-[36px]">{currentData?.question?.cosigner?.title}</Typography>
          </Box>
          <Box className="w-full !px-[40px] !py-[16px]">
            <div className="flex items-center justify-between">
              {isPc
                ? guaranteeList.map((g, ind) => (
                    <Box
                      onClick={() => {
                        onChangeGuarantee(g.value)
                      }}
                      className={`${
                        cosigner === g.value ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                      } flex items-center cursor-pointer justify-start  p-[32px] !font-Averta4 w-[45%] !text-[28px] border rounded-[32px]`}
                      key={ind}
                    >
                      <Box
                        className={`w-[32px] h-[32px] border rounded-[32px] mr-[32px] ${
                          g.value === cosigner ? 'border-[#E74F4F] border-[12px]' : 'border-[#E0E2EF] '
                        }`}
                      />
                      <Typography className="!font-Averta4 !text-[28px] !leading-[48px]">{g.label}</Typography>
                    </Box>
                  ))
                : guaranteeList.map((g, index) => (
                    <Chip
                      onClick={() => {
                        onChangeGuarantee(g.value)
                      }}
                      className={`${
                        cosigner === g.value ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                      } !font-Averta4 w-[45%] !text-[28px] border rounded-[100px]`}
                      label={<span className="font-Averta4 text-[28px] leading-[48px]">{g.label}</span>}
                      key={index}
                      variant="outlined"
                    />
                  ))}
            </div>
          </Box>
        </>
      )}
    </>
  )
}

export default CreditScore
