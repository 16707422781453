export const PreferenceList = [
  {
    value: 'UPPER_FLOOR',
    label: 'Upper Floor'
  },
  {
    value: 'DAYLIGHT',
    label: 'Daylight'
  },
  {
    value: 'FACE_COURTYARD',
    label: 'Face Courtyard'
  },
  {
    value: 'FACE_STREET',
    label: 'Face Street'
  },
  {
    value: 'MORE_STORAGE',
    label: 'More Storage'
  }
]

export const TourCancelReasonList: string[] = [
  'Time conflict',
  'No longer interested in this property',
  'No longer available ',
  'Other reason'
]

export const TourStatusList = {
  SCHEDULED: 'scheduled',
  CANCELED: 'canceled. Feel free to reschedule anytime.',
  FINISHED: 'completed'
}

export const TourTypeList = [
  {
    key: 'IN_PERSON_TOUR',
    name: 'In-person tour'
  },
  {
    key: 'IN_PERSON_TOUR (Group)',
    name: 'In-person tour (Group)'
  },
  {
    key: 'VIRTUAL_TOUR',
    name: 'Virtual tour'
  },
  {
    key: 'SELF_GUIDED',
    name: 'Self guided tour'
  }
]

export const getTourTypeName = (key: string) => TourTypeList.find(item => item.key === key)?.name || '-'

export interface AvailableUnit {
  id: string
  name: string
  rentPrices: {
    discountAmount: number
    leaseTerm: number
  }[]
  intention: boolean
  availableStatus: number
  preTourEndDate: number
  preTourStartDate: number
  availableStartTime: number
}
export interface FloorPlanModeUnitType {
  availableNow: boolean
  availableTime: number
  floorPlanBathroomNum: string
  floorPlanBedroomNum: string
  floorUrl: string
  maxArea: string
  minArea: string
  availableUnits: AvailableUnit[]
  floorPlanName: string
  floorPlanIndex: number
}

export interface ScheduleTourPropertyType {
  id: string
  aliasName: string
  name: string
  address: string
  headPicUrl: string
  availableStartTime: number
  coLivingStatus: 'CO-LIVING' | null
  flexibleLeaseTermTip: string | null
  minPrice: string
  minOriginalPrice: string
  floorPlanModeUnit: FloorPlanModeUnitType[]
  amenities: { catalogName: string }[]
}

export interface ScheduleTourTourType {
  propertyName: string
  id: string
  propertyId: string
  status: 'SCHEDULED' | 'FINISHED' | 'CANCELED'
  tourAgentId: string
  tourAgentName: string
  startTime: string
  timeZoneText: string
  timeZone: string
  propertyAddress: string
  preference: string[]
  units: { floorPlanName: string; unitId: number; unitName: string }[]
  tourType: 'IN_PERSON_TOUR' | 'VIRTUAL_TOUR'
  eventId: string
  cancelReason: string
  cancelNote: string
}

export interface SelectedUnitInfoProps {
  floorPlanIndex: number
  floorPlanName: string
  floorPlanTitle: string
  units: {
    name: string
    id: number
    availableStatus: number
    preTourEndDate: number
    preTourStartDate: number
    availableStartTime: number
  }[]
}

export interface PreSelectedUnitInfo {
  propertyId: number
  propertyName: string | null
  roomIds: number[]
  unitId: number
  floorPlanName: string
}

export interface OtherUnitInfoProps {
  floorPlanIndex: number
  floorPlanName: string
  floorPlanTitle: string
  name: string
}
export interface selfGuidTourUnitsProps {
  floorPlanName: string
  modelUnitId: number
  modelUnitName: string
  units: { unitId: number; unitName: string }[]
}

export type PageTypeType = 'unit' | 'preference' | 'submitTour' | 'tourInfo'
export type SelectedUnitsType = Map<string, { unitsInfo: SelectedUnitInfoProps; otherUnit: OtherUnitInfoProps }>

export default {}
