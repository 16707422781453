import { Checkbox, Typography } from '@mui/material'
import React, { FC, useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { Statsig } from 'statsig-react'
import CheckImage from './checkImage'
import { AvailableUnit, FloorPlanModeUnitType, OtherUnitInfoProps, SelectedUnitInfoProps, SelectedUnitsType } from './contantsAndType'
import NoFloorPlan from '../../assets/imgs/noFloorPlan.png'

interface ScheduleTourFloorPlanProps {
  questionnaireId: string
  propertyId: string
  floorPlans: FloorPlanModeUnitType[]
  selectedUnits: SelectedUnitsType
  onchange: (selectedUnits: SelectedUnitsType) => void
}
const ScheduleTourFloorPlan: FC<ScheduleTourFloorPlanProps> = props => {
  const { floorPlans, selectedUnits, propertyId, onchange, questionnaireId } = props
  const onSelectValueChange = (value: { unitsInfo: SelectedUnitInfoProps; otherUnit: OtherUnitInfoProps } | undefined, key: string) => {
    const newSelectedUnits = new Map(selectedUnits)
    if (value) {
      newSelectedUnits.set(key, value)
    } else {
      newSelectedUnits.delete(key)
    }
    onchange(newSelectedUnits)
  }
  return (
    <div className="px-[40px] py-[16px] lg:py-[32px]  lg:pt-0 lg:px-[80px] grid grid-cols-1 gap-y-[40px]">
      {floorPlans.map(floor => (
        <ScheduleTourFloorPlanItem
          propertyId={propertyId}
          questionnaireId={questionnaireId}
          key={`${floor.floorPlanName}-${floor.floorPlanIndex}`}
          floorPlan={floor}
          value={selectedUnits.get(`${floor.floorPlanName}-${floor.floorPlanIndex}`)}
          onChange={value => {
            onSelectValueChange(value, `${floor.floorPlanName}-${floor.floorPlanIndex}`)
          }}
        />
      ))}
    </div>
  )
}

interface ScheduleTourFloorPlanItemProps {
  questionnaireId: string
  floorPlan: FloorPlanModeUnitType
  propertyId: string
  value: { unitsInfo: SelectedUnitInfoProps; otherUnit: OtherUnitInfoProps } | undefined
  onChange: (value: { unitsInfo: SelectedUnitInfoProps; otherUnit: OtherUnitInfoProps } | undefined) => void
}
const ScheduleTourFloorPlanItem: FC<ScheduleTourFloorPlanItemProps> = props => {
  const { floorPlan, value, onChange, propertyId, questionnaireId } = props
  const [checkImage, setCheckImage] = useState(false)
  const floorPlanPrice = useMemo(() => {
    const { availableUnits } = floorPlan
    let minPrice = 0
    let maxPrice = 0
    const isNull = availableUnits.every(unit => !Array.isArray(unit.rentPrices) || unit.rentPrices.length === 0)
    if (isNull) return { minPrice, maxPrice }

    availableUnits.forEach(unit => {
      if (!Array.isArray(unit.rentPrices) || unit.rentPrices.length === 0) return
      const price = Number(unit.rentPrices[0].discountAmount)
      if (minPrice === 0) {
        minPrice = price
      }
      if (price < minPrice) minPrice = price
      if (price >= maxPrice) maxPrice = price
    })
    return {
      minPrice: Number(minPrice / 100),
      maxPrice: Number(maxPrice / 100)
    }
  }, [floorPlan])
  const floorPlanTitle = useMemo(() => {
    const { floorPlanBedroomNum, floorPlanBathroomNum, floorPlanName } = floorPlan

    const planName = floorPlanName ? ` - ${floorPlanName}` : ''
    if (Number(floorPlanBedroomNum) === 0) {
      return `Studio${planName}`
    }
    return `${Number(floorPlanBedroomNum)} Bed ${Number(floorPlanBathroomNum)} Bath${planName}`
  }, [floorPlan])
  const floorPlanArea = useMemo(() => {
    const minArea = Number(floorPlan.minArea)
    const maxArea = Number(floorPlan.maxArea)
    return minArea === maxArea ? `${minArea} sqft` : `${minArea} - ${maxArea} sqft`
  }, [floorPlan])
  const selectFloorPlan = useMemo(() => value && value.unitsInfo.floorPlanName === floorPlan.floorPlanName, [value, floorPlan])

  const onCheckImage = () => {
    Statsig.logEvent('schedule_tour_click_unit_image', window.location.href, {
      questionnaireId,
      floorPlanName: floorPlan.floorPlanName,
      floorUrl: floorPlan.floorUrl,
      propertyId
    })
    setCheckImage(true)
  }
  const changeFloorPlanSelect = () => {
    if (value) {
      onChange(undefined)
    } else {
      onChange({
        unitsInfo: { floorPlanIndex: floorPlan.floorPlanIndex, floorPlanName: floorPlan.floorPlanName, units: [], floorPlanTitle },
        otherUnit: { floorPlanIndex: -1, floorPlanName: '', name: '', floorPlanTitle }
      })
    }
  }
  const changeUnitItemSelect = (unit: AvailableUnit) => {
    const { id, name, availableStatus, preTourEndDate, preTourStartDate, availableStartTime } = unit
    if (value) {
      const newValue = cloneDeep(value)
      const { units } = newValue.unitsInfo
      const target = units.findIndex(u => String(u.id) === String(id))
      if (target > -1) {
        units.splice(target, 1)
      } else {
        units.push({ name, id: Number(id), availableStatus, availableStartTime, preTourStartDate, preTourEndDate })
      }
      onChange(newValue)
    } else {
      onChange({
        unitsInfo: {
          floorPlanTitle,
          floorPlanIndex: floorPlan.floorPlanIndex,
          floorPlanName: floorPlan.floorPlanName,
          units: [{ name, id: Number(id), availableStatus, availableStartTime, preTourStartDate, preTourEndDate }]
        },
        otherUnit: { floorPlanTitle, floorPlanIndex: -1, floorPlanName: '', name: '' }
      })
    }
  }
  const changeOtherUnitSelect = () => {
    if (value) {
      const newValue = cloneDeep(value)
      if (newValue.otherUnit.floorPlanIndex === floorPlan.floorPlanIndex) {
        newValue.otherUnit.floorPlanIndex = -1
        newValue.otherUnit.floorPlanName = ''
      } else {
        newValue.otherUnit.floorPlanIndex = floorPlan.floorPlanIndex
        newValue.otherUnit.floorPlanName = floorPlan.floorPlanName
      }
      onChange(newValue)
    } else {
      onChange({
        unitsInfo: { floorPlanTitle, floorPlanIndex: floorPlan.floorPlanIndex, floorPlanName: floorPlan.floorPlanName, units: [] },
        otherUnit: { floorPlanTitle, floorPlanIndex: floorPlan.floorPlanIndex, floorPlanName: floorPlan.floorPlanName, name: '' }
      })
    }
  }
  const changeOtherUnitInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trim()
    if (value) {
      const newValue = cloneDeep(value)
      newValue.otherUnit.name = input
      if (input) {
        newValue.otherUnit.floorPlanIndex = floorPlan.floorPlanIndex
        newValue.otherUnit.floorPlanName = floorPlan.floorPlanName
        newValue.otherUnit.floorPlanTitle = floorPlanTitle
      }
      onChange(newValue)
    } else if (input) {
      onChange({
        unitsInfo: { floorPlanTitle, floorPlanIndex: floorPlan.floorPlanIndex, floorPlanName: floorPlan.floorPlanName, units: [] },
        otherUnit: { floorPlanTitle, floorPlanIndex: floorPlan.floorPlanIndex, floorPlanName: floorPlan.floorPlanName, name: input }
      })
    }
  }

  return (
    <div className={`p-[32px] border rounded-[16px] ${selectFloorPlan ? 'border-[#050A22]' : 'border-[#E0E2EF]'}`}>
      <CheckImage
        src={floorPlan.floorUrl}
        alt=""
        onClose={() => {
          setCheckImage(false)
        }}
        visible={checkImage}
      />
      <div className="flex">
        {floorPlan.floorUrl ? (
          <img alt="" src={floorPlan.floorUrl} aria-hidden="true" onClick={onCheckImage} className="w-[176px] h-[176px]" />
        ) : (
          <div className="w-[176px] h-[176px] flex flex-col justify-center items-center">
            <img src={NoFloorPlan} alt="" className="w-[96px] h-[96px]" />
            <Typography className="!font-Averta6 font-bold !text-[24px] !text-[#050A22] !capitalize">Coming Soon</Typography>
          </div>
        )}
        <div
          aria-hidden="true"
          onClick={changeFloorPlanSelect}
          className={`${floorPlan.floorUrl ? 'ml-[32px]' : ''} flex-grow flex justify-between flex-col`}
        >
          <div className="w-full relative mb-[10px]">
            <div className="mr-[52px]">
              <Typography className="!font-Averta6 !font-semibold !text-[32px] !text-[#050A22] break-all">{floorPlanTitle}</Typography>
              <Typography className="table px-[16px] py-[4px] rounded-[36px] !text-[20px] !text-[#050A22] !font-Averta4 bg-slate-50">
                {floorPlanArea}
              </Typography>
            </div>
            <Checkbox
              className="!absolute !top-0 !right-0"
              sx={{
                color: '#E0E2EF',
                padding: '0',
                '&.Mui-checked': {
                  color: '#050A22'
                },
                '& svg': {
                  fontSize: '18px'
                }
              }}
              checked={!!value && value.unitsInfo.floorPlanIndex === floorPlan.floorPlanIndex}
            />
          </div>
          <div className="flex justify-between items-center">
            <Typography className="!font-Averta6 !text-[#050A22] !text-[32px]">{`$${floorPlanPrice.minPrice.toLocaleString()} - $${floorPlanPrice.maxPrice.toLocaleString()}`}</Typography>
          </div>
        </div>
      </div>
      {selectFloorPlan && (
        <div className="mt-[40px]">
          <Typography className="!font-averta !text-[28px] !text-[#050A22]">Select your preferred units (optional)</Typography>
          <div className="grid grid-cols-1 gap-y-[24px] mt-[24px]">
            {Array.isArray(floorPlan.availableUnits) &&
              floorPlan.availableUnits.length > 0 &&
              floorPlan.availableUnits.map(unit => {
                const { id, name, rentPrices, availableStatus } = unit
                return (
                  <div
                    key={id}
                    aria-hidden="true"
                    onClick={() => changeUnitItemSelect(unit)}
                    className="rounded-[16px] bg-[#F7F9FC] p-[16px]"
                  >
                    <div className="flex justify-between items-center leading-[60px]">
                      <Typography className="!font-Averta6 !text-[#050A22] !text-[32px] !font-semibold">{name}</Typography>
                      <div className="flex items-center">
                        <Typography className="!font-Averta6 !text-[#050A22] !text-[32px] !font-semibold">
                          {rentPrices.length > 0 ? `$${Number(rentPrices[0].discountAmount / 100).toLocaleString()}` : ''}
                        </Typography>
                        {rentPrices.length > 0 && rentPrices[0].leaseTerm && (
                          <Typography className="!ml-[8px] !text-[20px] lg:!text-[24px] !font-Averta4 !text-[#75798D]">
                            {rentPrices[0].leaseTerm} mo. lease
                          </Typography>
                        )}
                        &nbsp;&nbsp;
                        <Checkbox
                          sx={{
                            color: '#E0E2EF',
                            padding: '0',
                            '&.Mui-checked': {
                              color: '#050A22'
                            },
                            '& svg': {
                              fontSize: '18px'
                            }
                          }}
                          checked={!!value && value.unitsInfo.units.some(u => String(u.id) === String(id))}
                        />
                      </div>
                    </div>
                    {availableStatus === 0 && <div className="font-Averta4 text-[#050A22] text-[24px]">Tour available now</div>}
                    {availableStatus === 1 && (
                      <div className="font-Averta4 text-[#050A22] text-[24px]">
                        Tour available from {moment(unit.availableStartTime).format('MM/DD/YYYY')}
                      </div>
                    )}
                    {availableStatus === 2 && (
                      <div className="font-Averta4 text-[#050A22] text-[24px]">
                        Tour available from {moment(unit.preTourStartDate).format('MM/DD/YYYY')} to{' '}
                        {moment(unit.preTourEndDate).format('MM/DD/YYYY')}
                      </div>
                    )}
                  </div>
                )
              })}
            <div aria-hidden="true" onClick={changeOtherUnitSelect} className="rounded-[16px] bg-[#F7F9FC] p-[16px]">
              <div className="flex justify-between items-center leading-[60px]">
                <div className="flex items-center justify-start">
                  <Typography className="!mr-[16px] !font-Averta6 !text-[#050A22] !text-[32px] !font-semibold">Other Unit</Typography>
                  <input
                    value={value?.otherUnit.name}
                    onClick={e => e.stopPropagation()}
                    onChange={changeOtherUnitInput}
                    maxLength={10}
                    placeholder=" Unit Number"
                    className="border border-[#E0E2EF] font-Averta4 pl-[10px] rounded-[8px] w-[200px] leading-[48px] text-[28px] text-[#050A22]"
                  />
                </div>
                <Checkbox
                  sx={{
                    color: '#E0E2EF',
                    padding: '0',
                    '&.Mui-checked': {
                      color: '#050A22'
                    },
                    '& svg': {
                      fontSize: '18px'
                    }
                  }}
                  checked={!!value && value.otherUnit.floorPlanIndex === floorPlan.floorPlanIndex}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ScheduleTourFloorPlan
