import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Box, Divider, Stack, Typography } from '@mui/material'
import PropertyCard from './propertyCard'
import { ScheduleTourPropertyType } from './contantsAndType'
import TourCompleteImg from '../../assets/imgs/tourComplete.png'
import Logo from '../../icons/tripalinkTourV2/logo'
import { getCurrency } from '../../utils/index'
import { QUERY_PREFERENCE_BY_QUESTIONNAIRE_ID, QUESTIONNAIRE_CHECK_V2 } from '../../graphqls/queries'

const IMG_URL = process.env.REACT_APP_IMG_URL
const TourDetailImg = `${IMG_URL}0674ca3028875846.png`
const TourCancelImg = `${IMG_URL}59c95d51933c34c0.png`

const TourHeaderImg = {
  SCHEDULED: TourDetailImg,
  CANCELED: TourCancelImg,
  FINISHED: TourCompleteImg
}

type UnitType = {
  rooms: {
    roomId: string
    roomName: string
  }[]
  unitId: string
  unitName: string
}

interface ScheduleTourDetailProps {
  propertyId: string
  propertyList: ScheduleTourPropertyType[]
  onClickCard: (propertyId: string) => void
}
const ScheduleTourDetail = (props: ScheduleTourDetailProps) => {
  const { propertyId, propertyList, onClickCard } = props
  const { questionnaireId = '', signature = '', organizationId = '', version = '', channel = '' } = useParams()
  const { data } = useQuery(QUESTIONNAIRE_CHECK_V2, {
    variables: {
      request: { id: questionnaireId, sign: signature, version: Number(version), organizationId }
    }
  })
  const { questionnaireCheckV2 } = data || {}
  const { questionList } = questionnaireCheckV2 || {}

  const { data: { queryPreferenceByQuestionnaireId } = {} } = useQuery(QUERY_PREFERENCE_BY_QUESTIONNAIRE_ID, {
    fetchPolicy: 'network-only',
    variables: {
      questionnaireId
    }
  })

  const {
    bathRoomQuantity,
    bedRoomQuantity,
    budgetMin,
    budgetMax,
    desiredHomeList = [],
    leaseTerm,
    moveInDateBegin
  } = queryPreferenceByQuestionnaireId || {}

  const desiredHome = useMemo(
    () => desiredHomeList?.find((home: { propertyId: string }) => String(home.propertyId) === String(propertyId)),
    [propertyId, desiredHomeList]
  )

  const { propertyName, unitList = [], propertyAddress = {} } = desiredHome || {}

  const noTourPropertyList = useMemo(() => propertyList.filter(property => String(propertyId) !== String(property.id)), [propertyId])

  const displayFloorPlanData = useMemo(() => {
    if (!questionList) return null
    let str = ''

    const originData = JSON.parse(questionList || '[]')

    const floorPlanData = originData?.find((item: { code: string }) => item?.code === 'floorPlan')
    const tempBedroomList = floorPlanData?.question?.bedroom?.filter((item: { key: string }) => bedRoomQuantity?.includes(item.key))
    const tempBathroomList = floorPlanData?.question?.bathroom?.filter((item: { key: string }) => bathRoomQuantity?.includes(item.key))
    const displayBedSuffix = tempBedroomList?.length > 1 || (tempBedroomList?.length === 1 && tempBedroomList[0]?.key !== '0')
    const displayBedSemicolon = tempBathroomList?.length > 0
    str = `${tempBedroomList?.map((i: { value: string }) => i.value)?.join(', ')}${displayBedSuffix ? ' bed(s)' : ''}${
      displayBedSemicolon ? ';' : ''
    }`

    if (tempBathroomList?.length > 0) {
      str += `${tempBathroomList?.map((i: { value: string }) => i?.value)?.join(',')} bath;`
    }

    return str
  }, [bedRoomQuantity, bathRoomQuantity, questionList])

  return (
    <div className="pm-[40px] schedule-tour-detail">
      <Stack direction="row" justifyContent="center">
        <Logo />
      </Stack>
      <div className="px-[40px] py-[32px] flex flex-col items-center mt-[28px]">
        <img className="w-[160px] h-[160px]" src={TourHeaderImg.SCHEDULED} alt="tour detial" />
        <Typography className="!mt-[32px] !font-Averta6 !text-[36px] !text-center">We received your preferences, thanks!</Typography>

        <Typography className="!font-Averta4 !text-[28px] !text-center text-gray-500">Our leasing team will contact you soon.</Typography>
      </div>

      <div className="w-[calc(100%-80px)] m-auto my-[32px] rounded-[16px] bg-slate-50 p-[32px]">
        <div className="grid grid-cols-1 gap-y-[32px]">
          {(bedRoomQuantity || bathRoomQuantity) && (
            <>
              <div className="flex items-center justify-between leading-[48px]">
                <Typography className="!font-Averta4 !text-[28px] !text-[#050A22]">Floor plan</Typography>
                <Typography className="!font-Averta6 !text-[28px] !text-[#050A22]">{displayFloorPlanData}</Typography>
              </div>
              <Divider orientation="horizontal" />
            </>
          )}

          <div className="flex items-center justify-between leading-[48px]">
            <Typography className="!font-Averta4 !text-[28px] !text-[#050A22]">Budget</Typography>
            <Typography className="!font-Averta6 !text-[28px] !text-[#050A22]">{`$${getCurrency(budgetMin)} - $${getCurrency(
              budgetMax
            )}`}</Typography>
          </div>
          <Divider orientation="horizontal" />
          <div className="flex items-center justify-between leading-[48px]">
            <Typography className="!font-Averta4 !text-[28px] !text-[#050A22]">Move-in Date</Typography>
            <Typography className="!font-Averta6 !text-[28px] !text-[#050A22]">{moment(moveInDateBegin).format('L')}</Typography>
          </div>
          <Divider orientation="horizontal" />
          <div className="flex items-center justify-between leading-[48px]">
            <Typography className="!font-Averta4 !text-[28px] !text-[#050A22]">Lease Term</Typography>
            <Typography className="!font-Averta6 !text-[28px] !text-[#050A22]">{leaseTerm}</Typography>
          </div>
          <Divider orientation="horizontal" />
          <div className="flex items-start justify-between leading-[48px]">
            <Typography className="!font-Averta4 !text-[28px] !text-[#050A22]">Desired home</Typography>

            <div className="w-2/3 text-right">
              <Typography className="!font-Averta6 !text-[28px] !text-[#050A22]">{propertyName}</Typography>
              {unitList.map((unit: UnitType) => (
                <Typography key={unit.unitId} className="!font-Averta6 !text-[28px] !text-[#050A22]">
                  {unit.unitName}, {unit.rooms.map(room => room.roomName).join(',')}
                </Typography>
              ))}
              <Typography className="!font-Averta4 !text-[28px] !text-[#050A22]">{propertyAddress?.fullAddress}</Typography>
            </div>
          </div>
        </div>
      </div>

      {noTourPropertyList.length > 0 && (
        <div className="w-[calc(100%-80px)] m-auto mt-[64px] mb-[32px]">
          <Typography className="!font-Averta6 !text-[#181414] !text-[36px] !mb-[32px]">Other homes you may be interested in</Typography>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-2.5 gap-y-[40px]">
            {noTourPropertyList.map(item => (
              <PropertyCard property={item} key={item.id} onClick={() => onClickCard(item.id)} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ScheduleTourDetail
