import baseHooks from './base'
import floorPlanHooks from './floorPlan'
import budgetHooks from './budget'
import moveInDateHooks from './moveInDate'
import creditScoreHooks from './creditScore'
import leaseTermHooks from './leaseTerm'
import stayPlaningHooks from './stayPlaning'
import parkingHooks from './parking'

export default {
  baseHooks,
  budgetHooks,
  floorPlanHooks,
  moveInDateHooks,
  creditScoreHooks,
  leaseTermHooks,
  stayPlaningHooks,
  parkingHooks
}
