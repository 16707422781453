import React, { SvgIcon, SvgIconProps } from '@mui/material'

export default function TourType(props: SvgIconProps) {
  const { color } = props
  return (
    <SvgIcon {...props}>
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.7896 6.8909L8.35363 0.459654C8.30729 0.413223 8.25225 0.376387 8.19166 0.351253C8.13106 0.32612 8.06611 0.313183 8.00051 0.313183C7.93491 0.313183 7.86995 0.32612 7.80936 0.351253C7.74876 0.376387 7.69372 0.413223 7.64738 0.459654L1.21144 6.8909C1.02394 7.0784 0.917694 7.33309 0.917694 7.59872C0.917694 8.15028 1.36613 8.59872 1.91769 8.59872H2.59582V13.1878C2.59582 13.4643 2.81926 13.6878 3.09582 13.6878H7.00051V10.1878H8.75051V13.6878H12.9052C13.1818 13.6878 13.4052 13.4643 13.4052 13.1878V8.59872H14.0833C14.3489 8.59872 14.6036 8.49403 14.7911 8.30497C15.1802 7.91434 15.1802 7.28153 14.7896 6.8909Z"
          fill={color ?? '#050A22'}
        />
      </svg>
    </SvgIcon>
  )
}
