import React, { Dispatch, memo } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

interface Props {
  step: number
  setStep: Dispatch<number>
  loading?: boolean
  disabled?: boolean
}
const getButton = ({
  step,
  next,
  back,
  loading = false,
  disabled = false,
}: {
  step: number
  next: () => void
  back: () => void
  loading: boolean | undefined
  disabled: boolean | undefined
}) => {
  const buttonList = [
    <Button variant="contained" disabled={disabled} type="submit" className="w-full">
      Confirm and Next
    </Button>,
    <Box className="flex gap-[32px]">
      <Button
        variant="outlined"
        onClick={back}
        className="w-auto border-default text-default"
        sx={{
          color: '#050A22',
          fontSize: 16,
          borderColor: '#050A22',
          padding: '12px 28px',
          width: '121px',
          textTransform: 'none',
          fontWeight: 600,
        }}
      >
        Back
      </Button>
      <LoadingButton
        loading={loading}
        disabled={disabled}
        type="submit"
        variant="contained"
        className={`w-full ${disabled || loading ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-red-450 !text-[#fff]'}`}
      >
        Schedule
      </LoadingButton>
    </Box>,
  ]
  return buttonList[step]
}
const ButtonBar = ({ step, setStep, loading, disabled }: Props) => {
  const onClickNext = () => {
    setStep(step + 1)
  }
  const onClickBack = () => {
    setStep(step - 1)
  }

  return (
    <Box className="fixed bottom-0 left-0 right-0 p-[32px] shadow-topShadow bg-white">
      <Box className="max-w-[1000px] mx-auto">{getButton({ step, next: onClickNext, back: onClickBack, loading, disabled })}</Box>
    </Box>
  )
}

export default memo(ButtonBar)
