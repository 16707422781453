export default {
  shape: {
    borderRadius: 12,
    borderColor: '#050A22',
  },
  palette: {
    primary: {
      light: '#E74F4F',
      main: '#E74F4F',
    },
    secondary: {
      light: '#75798D',
      main: '#f44336',
    },
  },
  typography: {
    fontFamily: 'Averta CY',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1179, // for the ipad rotate width: ipad 旋转屏幕
      xl: 1536,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        sx: {
          '.MuiSvgIcon-root': { fontSize: '24px' },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#050A22 !important',
            borderWidth: 1,
            color: '#050A22',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#050A22',
            borderWidth: 1,
            color: '#050A22',
          },
          fontSize: 16,
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E0E2EF',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#050A22',
            borderWidth: 1,
            color: '#050A22',
          },
          '& .MuiOutlinedInput-input': {
            padding: '16px 20px',
            height: '24px',
            fontSize: 16,
          },
          '& .MuiSelect-icon': {
            top: 'unset',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          '&.Mui-focused': {
            color: '#4F5562',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          fontFamily: 'Averta CY',
          textTransform: 'unset',
          boxShadow: 'none',
          fontSize: 16,
          fontWeight: 600,
          padding: '12px 32px',
          shadow: 'none',

          '&.Mui-disabled': {
            backgroundColor: '#F4F5FA',
            color: '#A6A9BB',
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        sx: {
          '.MuiDrawer-paper': { borderRadius: '24px 24px 0 0' },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontSize: 16,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        sx: {
          fontSize: 14,
          lineHeight: 'unset',
          fontWeight: 400,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          width: 24,
        },
      },
    },
  },
}
