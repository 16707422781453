import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { PageTypeType } from './contantsAndType'

interface FooterProps {
  onClick: () => void
  onBack: () => void
  type: PageTypeType
  disabled: boolean
  nextLoading: boolean
  isReschedule?: boolean
  selectedCount?: number
  maxUnit?: number
}

const ScheduleTourFooter: FC<FooterProps> = props => {
  const { onClick, onBack, type, disabled, nextLoading, isReschedule, selectedCount, maxUnit } = props

  return (
    <Box className="fixed flex justify-between items-center w-full  bottom-0 py-[32px] px-[40px] bg-white shadow-footerShadow">
      {type === 'unit' && (
        <Box
          onClick={() => {
            if (disabled) return
            onClick()
          }}
          className={`${disabled ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-[#E74F4F] !text-white'
            } w-full text-center !rounded-[24px] !py-[24px] !px-[80px]`}
        >
          <Typography className="!text-[32px] !font-Averta6 font-semibold">
            Schedule tour<span>{` (${selectedCount}/${maxUnit} selected)`}</span>
          </Typography>
        </Box>
      )}
      {type !== 'unit' && (
        <>
          <Box
            onClick={onBack}
            className=" w-[calc(50%-16px)] !rounded-[24px] !py-[24px] !px-[80px] !bg-white !text-[#050A22] border border-[#E0E2EF] text-center"
          >
            <Typography className="!text-[32px] !font-Averta6 font-semibold  capitalize">{isReschedule ? 'Cancel' : 'Back'}</Typography>
          </Box>
          <LoadingButton
            onClick={() => {
              if (disabled) return
              onClick()
            }}
            loading={nextLoading}
            className={`${disabled || nextLoading ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-[#E74F4F] !text-white'
              } w-[calc(50%-16px)] !rounded-[24px] !py-[24px] !px-[40px] text-center`}
          >
            <Typography className="!text-[30px] !font-Averta6 font-semibold  capitalize">
              {type === 'preference' && 'Next'}
              {type === 'submitTour' && isReschedule ? 'Reschedule' : ''}
              {type === 'submitTour' && !isReschedule ? 'Confirm tour' : ''}
            </Typography>

          </LoadingButton>
        </>
      )}
    </Box>
  )
}

export default ScheduleTourFooter
