import { Typography, Box } from '@mui/material'
import React, { FC } from 'react'
import moment from 'moment'
import { RangePicker as ReactRangePicker } from 'react-trip-date'

interface MoveDateV4Props {
  moveInDate: { from: string; to: string }
  onChange: (moveInDate: { from: string; to: string }) => void
}
const MoveDateV4: FC<MoveDateV4Props> = props => {
  const { moveInDate, onChange } = props
  return (
    <>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">When will you move in ?</Typography>
        <Typography className="!font-Averta4 !text-[28px] !mt-[16px] text-[#75798D]">
          Even if you&apos;re not exactly sure, try and give us an estimate so we can show you what&apos;s available.
        </Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[32px] min-h-[756px]">
        <ReactRangePicker
          components={{
            header: {
              format: `MMMM YYYY`,
            },
          }}
          numberOfMonths={1}
          autoResponsive={false}
          initialMonthAndYear={moveInDate.from || moment()?.format('YYYY-MM-DD')}
          onChange={onChange}
          disabledBeforeToday
          selectedDays={{ from: moveInDate.from, to: moveInDate.from }}
        />
      </Box>
    </>
  )
}

export default MoveDateV4
