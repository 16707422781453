import React, { SvgIcon, SvgIconProps } from '@mui/material'

export default function TourTypeIcon(props: SvgIconProps) {
  const { color } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 6.35791V14.0635C0.5 14.6876 1.00598 15.1936 1.63014 15.1936H7.68001C6.73634 14.4755 6.15069 13.474 6.15069 12.3661C6.15069 10.1815 8.42759 8.41058 11.2363 8.41058C12.2818 8.41058 13.2535 8.65594 14.0616 9.07666V6.35791C14.0616 6.01031 13.9017 5.68204 13.6279 5.46784L7.97722 1.0467C7.56812 0.72662 6.99352 0.72662 6.58442 1.0467L0.933735 5.46784C0.659966 5.68204 0.5 6.01031 0.5 6.35791ZM11.3905 14.4707C14.5517 14.4707 15.5001 12.005 15.5001 12.005C15.5001 12.005 14.0844 9.5392 11.3905 9.5392C8.86153 9.5392 7.28092 12.005 7.28092 12.005C7.28092 12.005 8.22929 14.4707 11.3905 14.4707ZM12.6237 12.0048C12.6237 12.6857 12.0717 13.2377 11.3908 13.2377C10.7099 13.2377 10.1579 12.6857 10.1579 12.0048C10.1579 11.3239 10.7099 10.7719 11.3908 10.7719C12.0717 10.7719 12.6237 11.3239 12.6237 12.0048Z"
          fill={color ?? '#050A22'}
        />
      </svg>
    </SvgIcon>
  )
}
