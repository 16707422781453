import React from '@mui/material'

export default function QuestionCircle(props: { color?: any; className?: string }) {
  const { color } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.5C2 4.29086 3.79086 2.5 6 2.5C8.20914 2.5 10 4.29086 10 6.5C10 8.70914 8.20914 10.5 6 10.5C3.79086 10.5 2 8.70914 2 6.5ZM6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5ZM5.15001 5.49465V5.37936C5.15061 5.24218 5.19967 5.09001 5.30913 4.97561C5.41022 4.86994 5.60726 4.75 5.99713 4.75C6.36323 4.75 6.61782 4.92479 6.74765 5.12324C6.8863 5.33516 6.85898 5.51298 6.79318 5.60182C6.71111 5.71261 6.60921 5.81495 6.48606 5.92581C6.4486 5.95953 6.40242 5.99972 6.3529 6.0428L6.35289 6.04281C6.26435 6.11986 6.16515 6.20619 6.08605 6.28145C5.80517 6.54871 5.50009 6.92171 5.50009 7.5L5.50172 7.75321L6.5017 7.74679L6.5001 7.49863C6.50048 7.32762 6.57058 7.20077 6.77537 7.0059C6.84422 6.9404 6.9061 6.88686 6.9769 6.8256L6.97693 6.82558C7.0293 6.78027 7.08655 6.73074 7.15512 6.66902C7.29771 6.54066 7.45638 6.38653 7.59674 6.19703C7.97579 5.6853 7.88715 5.0384 7.58447 4.57576C7.27297 4.09963 6.70621 3.75 5.99713 3.75C5.38351 3.75 4.90778 3.94857 4.58658 4.28428C4.27393 4.61106 4.15114 5.02279 4.15001 5.37683V5.49465H5.15001ZM5.50171 8.25V9.25194H6.50171V8.25H5.50171Z"
        fill={color || '#86909C'}
      />
    </svg>
  )
}
