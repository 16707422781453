import React, { useState, useEffect } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useMutation } from '@apollo/client'
import { Statsig } from 'statsig-react'
import { useSearchParams } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { TOUR_CONFIRM } from '../../graphqls/mutations'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />)

const ChatgptTour = () => {
  const [urlParams] = useSearchParams()

  const [open, setOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const calendlyUrl = urlParams.get('calendlyUrl')
  const propertyName = urlParams.get('propertyName') || ''
  const address = urlParams.get('adderss')

  useEffect(() => {
    Statsig.logEvent('survey_prospect_ai_tour_pv', window.location.href, {
      source: 'ai-leasing',
    })
  }, [])

  const [submitCallback] = useMutation(TOUR_CONFIRM, {
    onError: error => {
      setErrorMsg(error.message)
      setOpen(true)
    },
  })

  useCalendlyEventListener({
    onEventScheduled: e => {
      submitCallback({
        variables: {
          input: {
            eventId: e.data.payload.event.uri,
            inviteeId: e.data.payload.invitee.uri,
            prospectId: Number(urlParams.get('prospectId')),
            propertyId: urlParams.get('propertyId') ? Number(urlParams.get('propertyId')) : null,
          },
        },
      })
    },
  })

  const prefill = {
    email: urlParams.get('email') || '',
    name: urlParams.get('name') || '',
    customAnswers: {
      a1: urlParams.get('phone') || '',
      a2: propertyName,
      a3: urlParams.get('unit') || '',
    },
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      <div className="chatgptTour-box">
        <div className="info m-3" style={{ fontSize: '16px' }}>
          {propertyName && <b>Tour Property: {propertyName}</b>}

          {address && (
            <>
              <br />
              <span>Address: {address}</span>
            </>
          )}
        </div>
        <InlineWidget url={calendlyUrl} prefill={prefill} />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ChatgptTour
