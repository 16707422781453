import { Typography, Box, Slider } from '@mui/material'
import React, { FC, useRef, useState } from 'react'

interface LeaseTermV4Props {
  leaseTerm: number
  onChange: (leaseTerm: number) => void
}

const LeaseTermV4: FC<LeaseTermV4Props> = props => {
  const { leaseTerm, onChange } = props
  const [open, setOpen] = useState(false)
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const sliderChange = (_: unknown, value: number | number[]) => {
    if (typeof value === 'number') onChange(value)
  }
  return (
    <>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">How long are you planning to stay?</Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Box
          ref={anchorEl}
          aria-describedby="month-popover"
          onClick={() => {
            setOpen(!open)
          }}
          className="flex bg-[#F7F9FC] rounded-[24px] py-[10px] px-[32px] justify-between items-center"
        >
          <div className="flex items-start flex-col">
            <span className="text-[#75798D] text-[24px] font-Averta4 mb-[8px]">Lease Term (Month)</span>
            <span className="text-[#050A22] text-[32px] font-Averta4">
              {leaseTerm} {leaseTerm === 1 ? 'month' : 'months'}
            </span>
          </div>
        </Box>
        <Slider
          className="mt-[24px]"
          onChange={sliderChange}
          sx={{
            '& .MuiSlider-rail': {
              bgcolor: '#E0E2EF',
            },
          }}
          step={1}
          value={leaseTerm}
          max={24}
          min={1}
          color="secondary"
        />
      </Box>
    </>
  )
}

export default LeaseTermV4
