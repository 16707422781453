import React from 'react'
import momentTimeZone from 'moment-timezone'
import { Statsig } from 'statsig-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import HouseIcon from '../../icons/tripalinkTourV2/house'
import DateIcon from '../../icons/tripalinkTourV2/date'
import AddressIcon from '../../icons/tripalinkTourV2/address'
import Earth from '../../icons/tripalinkTourV2/earth'

export interface DataSourceType {
  customerNote: string
  tourAgentName: string
  propertyAddress: string
  prospectPhone: string
  tourType: string
  startTime: string
  timeZoneText: string
  timeZone: string
  status: string
}

interface Props {
  dataSource: DataSourceType
  loading: boolean
}

const TourBaseInfoCard = ({ dataSource, loading }: Props) => {
  const { customerNote, tourAgentName, propertyAddress, prospectPhone, tourType, startTime, timeZoneText, timeZone, status } =
    dataSource || {}

  return (
    <Box className={`flex flex-col gap-[16px] ${status === 'SCHEDULED' ? 'block' : 'hidden'}`}>
      <Box className="flex flex-col rounded-[32px] bg-blue-50">
        <Box className="px-[40px] py-[24px] flex flex-col gap-[16px]">
          {loading ? (
            <Box>
              <Skeleton height={40} />
              <Skeleton height={40} />
            </Box>
          ) : (
            <>
              <Box className="flex items-center gap-[16px]">
                <Typography className="font-averta text-gray-550 w-[140px]">Tour Host</Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: 8,
                  }}
                />
                <span className="font-averta font-semibold capitalize text-[32px] text-default">{tourAgentName || '-'}</span>
              </Box>
              <Box className="flex items-center gap-[16px]">
                <Typography className="font-averta text-gray-550 w-[140px]">Phone</Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: 8,
                  }}
                />
                <span className="font-averta font-semibold text-[32px] text-default">{prospectPhone || '-'}</span>
              </Box>
            </>
          )}
        </Box>

        <Box className="px-[40px] py-[24px] flex flex-col gap-[32px] rounded-[32px] bg-white shadow-topShadow">
          {loading ? (
            <Box>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </Box>
          ) : (
            <>
              <Box className="flex gap-[16px] items-center">
                <HouseIcon />
                <span className="font-averta font-semibold text-[32px] text-default">{tourType || '-'}</span>
              </Box>
              <Box className="flex gap-[16px] items-start">
                <Box className="h-[48px] flex items-center">
                  <DateIcon />
                </Box>
                <span className="font-averta font-semibold text-[32px] text-default">
                  {startTime ? `${momentTimeZone(startTime).tz(timeZone).format('H:mm, dddd, MMM DD, YYYY')}` : '-'}
                </span>
              </Box>
              <Box className="flex gap-[16px] items-center">
                <Earth />
                <span className="font-averta font-semibold text-[32px] text-default">{timeZoneText || '-'}</span>
              </Box>
              <Box className="flex gap-[16px] items-center">
                <AddressIcon />
                <span className="font-averta font-semibold text-[32px] text-default">{propertyAddress || '-'}</span>
              </Box>
            </>
          )}
          {loading ? (
            <>
              <Divider
                sx={{
                  borderColor: '#E0E2EF',
                }}
              />
              <Skeleton height={40} />
            </>
          ) : (
            customerNote && (
              <>
                <Divider
                  sx={{
                    borderColor: '#E0E2EF',
                  }}
                />
                <Box>
                  <Typography
                    className="font-averta text-gray-550"
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    Additional Notes to the Tour host
                  </Typography>
                  {loading ? <Skeleton height={40} /> : <p className="font-averta font-semibold text-[32px]">{customerNote}</p>}
                </Box>
              </>
            )
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default TourBaseInfoCard
