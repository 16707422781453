const getFloorPlanDefaultInitData = (item: {
  question: {
    bedroomResult: string
    bathroomResult: string
  }
  thumbnailLabel: string
  code: string
  selectBedroom: string[]
  selectBathroom: string[]
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [
    { name: item?.question?.bedroomResult, value: item?.selectBedroom || null },
    { name: item?.question?.bathroomResult, value: item?.selectBathroom || null }
  ],
  displayData: item?.displayData,
  isCanNext: item?.isCanNext,
  label: item?.thumbnailLabel
})
const getBudgetDefaultInitData = (item: {
  question: {
    minBudgetResult: string
    maxBudgetResult: string
  }
  thumbnailLabel: string
  code: string
  minBudget: string
  maxBudget: string
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [
    { name: item?.question?.minBudgetResult, value: item?.minBudget || 0 },
    { name: item?.question?.maxBudgetResult, value: item?.maxBudget || 0 }
  ],
  displayData: item?.displayData,
  isCanNext: item?.isCanNext,
  label: item?.thumbnailLabel
})

const getMoveInDateDefaultInitData = (item: {
  question: {
    moveInDateResult: string
  }
  thumbnailLabel: string
  code: string
  moveInDate: {
    from: string
    to: string
  }
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [{ name: item?.question?.moveInDateResult, value: item?.moveInDate?.from || null }],
  displayData: item?.displayData,
  isCanNext: item?.isCanNext,
  label: item?.thumbnailLabel
})

const getCreditScoreDefaultInitData = (item: {
  question: {
    creditScoreResult: string
    isStudentResult: string
    cosignerResult: string
  }
  thumbnailLabel: string
  code: string
  creditScoreType: string
  isStudent: string
  cosigner: string
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [
    { name: item?.question?.creditScoreResult, value: item?.creditScoreType || null },
    { name: item?.question?.isStudentResult, value: item?.isStudent },
    { name: item?.question?.cosignerResult, value: item?.cosigner }
  ],
  isCanNext: item?.isCanNext,
  displayData: item?.displayData,
  label: item?.thumbnailLabel
})

const getStayPlaningDefaultInitData = (item: {
  question: {
    stayPlanResult: string
    stayLeaseTermResult: string
  }
  thumbnailLabel: string
  code: string
  stayPlanType: string
  leaseTerm: string
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [
    { name: item?.question?.stayPlanResult, value: item.stayPlanType || null },
    { name: item?.question?.stayLeaseTermResult, value: item?.leaseTerm || null }
  ],
  isCanNext: item?.isCanNext,
  displayData: item?.displayData,
  label: item?.thumbnailLabel
})

const getLeaseTermDefaultInitData = (item: {
  question: {
    leaseTermResult: string
  }
  thumbnailLabel: string
  code: string
  leaseTerm: string
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [{ name: item?.question?.leaseTermResult, value: item?.leaseTerm || null }],
  isCanNext: item?.isCanNext,
  displayData: item?.displayData,
  label: item?.thumbnailLabel
})

export const getParkingSpotDetaultInitData = (item: {
  question: {
    parkingSpotResult: string
  }
  thumbnailLabel: string
  code: string
  parking: number
  isCanNext: boolean
  displayData: string
}) => ({
  code: item?.code,
  submitValue: [{ name: item?.question?.parkingSpotResult, value: item?.parking || null }],
  isCanNext: item?.isCanNext,
  displayData: item?.displayData,
  label: item?.thumbnailLabel
})
const initDataFunction: any = {
  floorPlan: getFloorPlanDefaultInitData,
  budget: getBudgetDefaultInitData,
  moveInDate: getMoveInDateDefaultInitData,
  creditScore: getCreditScoreDefaultInitData,
  stayPlaning: getStayPlaningDefaultInitData,
  leaseTerm: getLeaseTermDefaultInitData,
  parkingSpot: getParkingSpotDetaultInitData
}

export default {
  initDataFunction
}
