import React, { SvgIcon, SvgIconProps } from '@mui/material'

export default function AddressIcon(props: SvgIconProps) {
  const { color } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5 7.19434C13.5 3.60448 10.5899 0.694336 7 0.694336C3.41015 0.694336 0.5 3.60448 0.5 7.19434C0.5 9.80801 2.04278 12.0611 4.26726 13.0933C4.80395 13.3423 5.32748 13.6333 5.74584 14.0517L6.15147 14.4573C6.6201 14.9259 7.3799 14.9259 7.84853 14.4573L8.25416 14.0517C8.67251 13.6333 9.19605 13.3423 9.73274 13.0933C11.9572 12.0611 13.5 9.80801 13.5 7.19434ZM7 4.99096C8.21689 4.99096 9.20338 5.97744 9.20338 7.19434C9.20338 8.41123 8.21689 9.39772 7 9.39772C5.78311 9.39772 4.79662 8.41123 4.79662 7.19434C4.79662 5.97744 5.78311 4.99096 7 4.99096Z"
          fill={color ?? '#050A22'}
        />
      </svg>
    </SvgIcon>
  )
}
