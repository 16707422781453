import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import NoUnitImg from '../../assets/imgs/noUnit.png'

export default function EndStep() {
  const isPCUI = useMediaQuery('(min-width:1024px)')
  return (<Box className="w-full !px-[40px] !py-[176px] flex flex-col justify-center items-center"> <img className="w-[160px] h-[160px]" src={NoUnitImg} alt="no unit" />
    <Typography className={`!mt-[32px] !font-Averta6 ${isPCUI ? '!text-[48px]' : '!text-[36px]'}`}>Got it,
      thanks!</Typography>
    <Typography className={`!mt-[16px] !font-Averta4 ${isPCUI ? '!text-[32px]' : '!text-[28px]'} !text-[#75798D]`}>
      Our leasing team will contact you soon.
    </Typography>
  </Box>)
}