import React, { useEffect, useMemo, useContext } from 'react'
import moment from 'moment'
import contextData from '../contextData'

interface MoveInDateProps {
  from: string
  to: string
}

const MoveInDateHooks = (currentIndex: number) => {
  const { moduleList, dataCollect, mergeDataCollectFunction } = useContext(contextData)
  const currentData = moduleList[currentIndex]
  const moveInDate = (useMemo(
    () => ({
      from: dataCollect?.[currentIndex]?.submitValue?.[0]?.value || '',
      to: dataCollect?.[currentIndex]?.submitValue?.[0]?.value || ''
    }),
    [dataCollect]
  ) as MoveInDateProps) || { from: '', to: '' }
  const isCanNext = useMemo(() => moveInDate?.from && moveInDate?.to, [moveInDate])
  const displayData = useMemo(() => moment(moveInDate?.from)?.format('MM/DD/YYYY'), [moveInDate])

  const onChange = (value: MoveInDateProps) => {
    const submitValue = [{ name: dataCollect[currentIndex]?.submitValue?.[0].name, value: value?.from || null }]
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, { submitValue })
    }
  }

  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData
      })
    }
  }, [isCanNext, displayData])

  return {
    currentData,
    moveInDate,
    onChange
  }
}

export default MoveInDateHooks
