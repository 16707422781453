import React, { SvgIcon, SvgIconProps } from '@mui/material'

export default function HouseIcon(props: SvgIconProps) {
  const { color } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.33301 8.00016H14.6663V14.0002C14.6663 14.177 14.5961 14.3465 14.4711 14.4716C14.3461 14.5966 14.1765 14.6668 13.9997 14.6668H1.99967C1.82286 14.6668 1.65329 14.5966 1.52827 14.4716C1.40325 14.3465 1.33301 14.177 1.33301 14.0002V8.00016ZM11.333 2.66683H13.9997C14.1765 2.66683 14.3461 2.73707 14.4711 2.86209C14.5961 2.98712 14.6663 3.15668 14.6663 3.3335V6.66683H1.33301V3.3335C1.33301 3.15668 1.40325 2.98712 1.52827 2.86209C1.65329 2.73707 1.82286 2.66683 1.99967 2.66683H4.66634V1.3335H5.99967V2.66683H9.99967V1.3335H11.333V2.66683Z"
          fill={color ?? '#050A22'}
        />
      </svg>
    </SvgIcon>
  )
}
