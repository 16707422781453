import { Chip, Typography } from '@mui/material'
import React, { FC } from 'react'
import { PreferenceList } from './contantsAndType'
import { checkIsMobile } from '../../utils/agent'

interface ScheduleTourPreferenceProps {
  selectedPreferences: string[]
  onChange: (preferences: string[]) => void
}

const isMobile = checkIsMobile()

const ScheduleTourPreference: FC<ScheduleTourPreferenceProps> = props => {
  const { selectedPreferences, onChange } = props

  const onClickItem = (item: string) => {
    if (selectedPreferences.includes(item)) {
      onChange(selectedPreferences.filter(i => i !== item))
    } else {
      onChange([...selectedPreferences, item])
    }
  }
  return (
    <>
      <div className="w-full px-[40px] py-[32px] lg:pt-[96px]">
        <Typography className="!font-Averta6  !text-[36px] !text-[#181414]">
          Select your preferences 👀{' '}
          {!isMobile ? <span className="!text-[#75798D] !mt-[16px] !text-[28px] !font-Averta4">Optional</span> : null}
        </Typography>
        {isMobile && <Typography className="!text-[#75798D] !mt-[16px] !text-[28px] !font-Averta4">Optional</Typography>}
      </div>
      <div className="w-full px-[40px] py-[32px] grid grid-cols-2 gap-y-[24px] gap-x-[32px]">
        {PreferenceList.map(item => (
          <Chip
            onClick={() => onClickItem(item?.label)}
            className={`${
              selectedPreferences.includes(item?.label) ? '!border-[#050A22] !bg-[#F7F9FC]' : '!border-[#E0E2EF]'
            } !font-Averta4 !text-[28px] border rounded-[100px]  lg:!px-[32px] lg:!py-[12px] lg:!h-auto`}
            label={<span className="font-Averta4 text-[28px] leading-[48px]">{item?.label}</span>}
            key={item?.label}
            variant="outlined"
          />
        ))}
      </div>
    </>
  )
}

export default ScheduleTourPreference
