/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash'
import { ScheduleTourPropertyType, SelectedUnitsType } from './contantsAndType'

export const calcSelectedCount = (units: SelectedUnitsType) => {
  let count = 0
  units.forEach(unit => {
    if (unit.unitsInfo.rooms.length > 0) {
      count += unit.unitsInfo.rooms.length
    }
    const onlySelectedFloorPlan = unit.unitsInfo?.rooms.length === 0
    if (onlySelectedFloorPlan) {
      count += 1
    }
  })
  return count

  return count
}

export const calcTimeOverlap = (selectedUnits: SelectedUnitsType) => {
  let min = -Infinity
  let max = Infinity
  let isOverlap = true
  selectedUnits.forEach(item => {
    item.unitsInfo.rooms.forEach(room => {
      if (room.availableStatus === 1) {
        if (room.availableStartTime > max) {
          isOverlap = false
          return
        }
        if (room.availableStartTime > min) {
          min = room.availableStartTime
        }
      } else if (room.availableStatus === 2) {
        if (room.preTourStartDate > max || room.preTourEndDate < min) {
          isOverlap = false
          return
        }
        if (room.preTourStartDate >= min) {
          min = room.preTourStartDate
          max = Math.min(max, room.preTourEndDate)
        }
        if (room.preTourStartDate < min) {
          max = Math.min(max, room.preTourEndDate)
        }
      }
    })
  })
  return { isOverlap, min, max }
}

export const refactSelectedUnitTime = (properties: ScheduleTourPropertyType[]) => {
  const cloneProperties = cloneDeep(properties)

  cloneProperties.forEach(property => {
    if (property.floorPlanModeUnit.length > 0) {
      property.floorPlanModeUnit.forEach(floorPlan => {
        const unitEarliestTime = { min: Infinity, max: -Infinity }
        floorPlan.availableUnits.forEach(unit => {
          if (unit.availableStatus === 1 && unit.availableStartTime < unitEarliestTime.min) {
            unitEarliestTime.min = unit.availableStartTime
            unitEarliestTime.max = -Infinity
          } else if (unit.availableStatus === 2 && unit.preTourStartDate < unitEarliestTime.min) {
            unitEarliestTime.min = unit.preTourStartDate
            unitEarliestTime.max = unit.preTourEndDate
          }
        })
        if (unitEarliestTime.min !== Infinity) {
          floorPlan.availableUnits.forEach(unit => {
            if (unit.availableStatus !== 0) {
              if (unitEarliestTime.max === -Infinity) {
                unit.availableStatus = 1
                unit.availableStartTime = unitEarliestTime.min
              } else {
                unit.availableStatus = 2
                unit.preTourStartDate = unitEarliestTime.min
                unit.preTourEndDate = unitEarliestTime.max
              }
            }
          })
        }
      })
    }
  })
  return cloneProperties
}

export const getStatsigProperties = (properties: ScheduleTourPropertyType[]) => {
  const statsigProperties = properties.map(property => {
    const floorPlanModeUnit = property.floorPlanModeUnit.map(floorPlan => {
      const availableUnits = floorPlan.availableUnits.map(unit => ({
        id: unit.id,
        name: unit.name
      }))
      return {
        // floorPlanName: floorPlan.floorPlanName,
        // floorUrl: floorPlan.floorUrl,
        floorPlanLabel: floorPlan.bedroomOption,
        availableUnits
      }
    })
    return {
      id: property.id,
      name: property.name,
      aliasName: property.aliasName,
      floorPlanModeUnit
    }
  })
  return statsigProperties
}

export const buildTourCoLivingsEventMetadata = (
  prospectId: string,
  currentProperty?: ScheduleTourPropertyType,
  selectedUnitMap?: SelectedUnitsType
) => {
  if (!currentProperty || !selectedUnitMap) return {}

  let totalRooms = 0
  currentProperty.floorPlanModeUnit?.forEach?.(floorPlan => {
    floorPlan.availableUnits?.forEach?.(unit => {
      totalRooms += unit?.rooms?.length ?? 0
    })
  })

  const selectedRoomList: unknown[] = []
  selectedUnitMap.forEach(floorPlan => {
    if (floorPlan.unitsInfo?.rooms?.length > 0) {
      floorPlan.unitsInfo.rooms.forEach(room =>
        selectedRoomList.push({
          unitId: floorPlan.unitsInfo.unitId,
          unitName: floorPlan.unitsInfo.unitName,
          roomId: room.id === -999 ? null : room.id,
          roomName: room.name
        })
      )
    } else {
      selectedRoomList.push({
        unitId: floorPlan.unitsInfo.unitId,
        unitName: floorPlan.unitsInfo.unitName
      })
    }
  })

  return {
    prospectId,
    propertyId: String(currentProperty.id),
    propertyName: currentProperty.name,
    numberOfFloorPlansShown: String(currentProperty?.floorPlanModeUnit?.length ?? 0),
    numberOfUnitsShown: String(
      currentProperty?.floorPlanModeUnit?.reduce((acc, floorModeUnit) => acc + floorModeUnit.availableUnits.length, 0) ?? 0
    ),
    numberOfRoomsShown: String(totalRooms),
    selectedUnits: JSON.stringify(selectedRoomList)
  }
}
