import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { BrowserRouter } from 'react-router-dom'
import { StatsigProvider } from 'statsig-react'
import * as FullStory from '@fullstory/browser'
import SentryFullStory from '@sentry/fullstory'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { statsigSdkKey } from './config.json'
import './index.less'
import AuthRoutes from './routes/authRoute'
import './app.less'
import './styles/font.less'

const { REACT_APP_FULLSTORY_ORG, REACT_APP_SENTRY_ORG, REACT_APP_STAGE, REACT_APP_SENTRY_DSN, REACT_APP_APOLLO_TOKEN } = process.env || window
 if (REACT_APP_STAGE === 'production' && REACT_APP_FULLSTORY_ORG) {
  FullStory.init({ orgId: REACT_APP_FULLSTORY_ORG })
}

if (REACT_APP_STAGE === 'production' && REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new SentryFullStory(REACT_APP_SENTRY_ORG || '', { client: FullStory }), new BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql' })
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(({ message, extensions: { errorType } = {} }: { message: string; extensions: any }) => {
      console.error(message)
      if (['PERMISSION_DENIED'].includes(errorType as string)) {
        console.error(errorType)
      }
    })
  }

  if (networkError) {
    console.error('networkError', networkError)
  }
})
const authMiddleware = new ApolloLink((operation: any, forward: any) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${REACT_APP_APOLLO_TOKEN}`
    }
  }))

  return forward(operation)
})

const link = from([authMiddleware, errorLink, httpLink])
const cache = new InMemoryCache({
  typePolicies: {
    LeaseContractDTO: {
      fields: {
        processing: {
          read: processing => !!processing
        }
      }
    }
  }
})
const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: !!process.env.REACT_APP_GRAPHQL_URI
})

ReactDOM.render(
  <StatsigProvider
    waitForInitialization
    sdkKey={statsigSdkKey}
    user={{
      userAgent: window?.navigator?.userAgent
    }}
    options={{ environment: { tier: REACT_APP_STAGE }, disableErrorLogging: REACT_APP_STAGE !== 'development' }}
  >
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthRoutes />
      </BrowserRouter>
    </ApolloProvider>
  </StatsigProvider>,
  document.getElementById('root')
)
