import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Skeleton,
  Typography,
  Box,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
  Stack,
  IconButton,
  Button,
  Snackbar,
  Alert
} from '@mui/material'
import { Statsig } from 'statsig-react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useParams, useSearchParams } from 'react-router-dom'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Logo from '../../assets/imgs/surveyLogo.svg'
import RentyLogo from '../../assets/imgs/rentyWhiteIcon.svg'
import warning from '../../assets/imgs/warning.svg'
import QuestionCircle from '../../icons/questionCircle'
import CalendarFilled from '../../icons/calendarFilled'
import ScheduleTourHeader from './scheduleTourHeader'
import ScheduleTourFooter from './scheduleTourFooter'
import ScheduleTourUnit from './scheduleTourUnit'
import { QUERY_PROPERTY_BY_QUESTIONNAIRE_ID_V1, QUERY_CHECKED_SHELTER } from '../../graphqls/queries'
import { SAVE_DESIRED_HOMES, SAVE_URL_TTL_LOG } from '../../graphqls/mutations'
import { ScheduleTourPropertyType, PageTypeType, SelectedUnitsType, PreSelectedUnitInfo } from './contantsAndType'
import ScheduleTourDetail from './scheduleTourDetail'
import { calcSelectedCount, getStatsigProperties, refactSelectedUnitTime, buildTourCoLivingsEventMetadata } from './utils'

import { checkIsMobile } from '../../utils/agent'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  })
)

const MAX_SELECTED_UNIT = 4
let logInterval: NodeJS.Timer | null = null
let errorMsgTimer: NodeJS.Timer
const isMobile = checkIsMobile()
const HEADER_HEIGHT = 65

const ScheduleTour = () => {
  const { questionnaireId = '', version } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const bedroomValue = searchParams.get('bedroomNum') || 'ALL'
  const [pageType, setPageType] = useState<PageTypeType>('unit')
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([])
  const [prospectId, setProspectId] = useState('')
  const [propertyList, setPropertyList] = useState<ScheduleTourPropertyType[]>([])
  const [selectProperty, setSelectProperty] = useState<ScheduleTourPropertyType>()
  const [selectedUnits, setSelectedUnits] = useState<SelectedUnitsType>(new Map())
  const [fixedDom, setFixedDom] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [openErrorMsg, setOpenErrorMsg] = useState(false)
  const [fetchError, setFetchError] = useState({
    open: false,
    msg: ''
  })
  const [saveUrlTtlLogApi] = useMutation(SAVE_URL_TTL_LOG, {
    onError() {
      // do nothing
    },
    variables: { input: { questionnaireId, source: 'TOUR' } }
  })

  const [checkedShelter] = useLazyQuery(QUERY_CHECKED_SHELTER, {
    onCompleted({ queryCheckedShelter }) {
      const { data } = queryCheckedShelter || {}
      // 获取第一条数据，查看当前选择的propertyId
      const [shelterChecked] = data || []

      if (shelterChecked) {
        if (Array.isArray(propertyList) && propertyList.length > 0) {
          const currentProperty = propertyList.find(item => item.id === String(shelterChecked.propertyId)) as ScheduleTourPropertyType
          setSelectProperty(currentProperty)
          const { floorPlanModeUnit } = currentProperty || {}
          const [units] = floorPlanModeUnit || []
          const { availableUnits } = units || []

          // 获取历史选中的units、rooms
          const preSelected = data.reduce((pre: SelectedUnitsType, shelter: PreSelectedUnitInfo) => {
            // 遍历所有unit
            availableUnits.forEach(unit => {
              const { floorPlanIndex, name, id: unitId, rooms } = unit || {}

              if (String(shelter.unitId) === String(unitId)) {
                pre.set(unit.name, {
                  unitsInfo: {
                    floorPlanIndex,
                    unitName: name,
                    unitTitle: name,
                    unitId: Number(unitId),
                    rooms: shelter.roomIds.map(roomId => {
                      const room = rooms.find(r => String(r.id) === String(roomId))

                      return {
                        id: Number(room?.id),
                        name: room?.name || '',
                        unitId: Number(room?.unitId),
                        availableStatus: room?.availableStatus || 0,
                        availableStartTime: room?.availableStartTime || 0,
                        preTourStartDate: room?.preTourStartDate || 0,
                        preTourEndDate: room?.preTourEndDate || 0
                      }
                    })
                  }
                })
              }
            })

            return pre
          }, new Map())
          setSelectedUnits(preSelected)
        }
      } else {
        setSelectProperty(propertyList?.[0])
      }
    }
  })

  const { loading } = useQuery(QUERY_PROPERTY_BY_QUESTIONNAIRE_ID_V1, {
    notifyOnNetworkStatusChange: true,
    variables: {
      questionnaireId
    },
    onCompleted(data) {
      const { searchPropertyByQuestionnaireIdV1 = {} } = data || {}
      const { propertyResult: properties, prospectId: currentProspectId } = searchPropertyByQuestionnaireIdV1

      const propertyResult = refactSelectedUnitTime(properties)
      setProspectId(currentProspectId || '')
      checkedShelter({
        variables: {
          prospectId: Number(currentProspectId)
        }
      })
      setPropertyList(Array.isArray(propertyResult) ? propertyResult : [])
      Statsig.logEvent('schedule_tour_recommend_property', window.location.href, {
        questionnaireId,
        prospectId: currentProspectId,
        properties: JSON.stringify(getStatsigProperties(propertyResult))
      })

      if (Array.isArray(propertyResult) && propertyResult.length > 0 && currentProspectId) {
        Statsig.logEvent('schedule_tour_default_select_property', window.location.href, {
          questionnaireId,
          pageType,
          prospectId: currentProspectId,
          propertyId: propertyResult[0].id
        })
      }
    },
    onError() {
      Statsig.logEvent('schedule_tour_query_property_error', window.location.href, { questionnaireId })
      // something bad
    }
  })

  const [saveLeasingWantTour] = useMutation(SAVE_DESIRED_HOMES, {
    onCompleted() {
      setPageType('tourInfo')
    },
    onError() {
      // nothing to do
    }
  })

  const selectedCount = useMemo(() => calcSelectedCount(selectedUnits), [selectedUnits])
  const disableNext = useMemo(() => {
    if (pageType === 'unit') return selectedCount === 0
    if (pageType === 'preference') return false // preference 选填
    return true
  }, [pageType, selectedPreferences, selectedCount])

  const isCoLivingProperty = useMemo(() => selectProperty?.coLivingStatus === 'CO-LIVING', [selectProperty?.coLivingStatus])

  const unitList = useMemo(() => {
    const { floorPlanModeUnit } = selectProperty || {}
    const currentFloorPlan = floorPlanModeUnit?.find(floor => floor.bedroomOption.value === bedroomValue)

    return currentFloorPlan?.availableUnits || []
  }, [bedroomValue, selectProperty?.id])

  const onSearchUnits = (event: MouseEvent<HTMLButtonElement>) => {
    const {
      currentTarget: {
        dataset: { bd }
      }
    } = event

    setSearchParams((prevSearchParams: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
      const newSearchParams = new URLSearchParams(prevSearchParams)
      newSearchParams.set('bedroomNum', bd || '')

      return newSearchParams
    })
  }

  const onSubmit = () => {
    let logs = {
      pageType,
      questionnaireId,
      prospectId: selectProperty?.id || ''
    }
    const selectValueObj = [...selectedUnits.entries()].reduce((obj, [key, value]) => {
      obj[key] = value
      return obj
    }, {} as Record<string, unknown>)

    const aiLeasingWantTourList: unknown[] = []

    if (selectProperty?.id) {
      if (isCoLivingProperty) {
        selectedUnits.forEach(unit => {
          if (!unit.unitsInfo.rooms?.length) {
            const tourListJson = [
              {
                propertyId: Number(selectProperty.id),
                propertyName: selectProperty.name,
                unitId: unit.unitsInfo.unitId,
                unitName: unit.unitsInfo.unitName
              }
            ]
            aiLeasingWantTourList.push({
              isColiving: true,
              prospectId: Number(prospectId),
              propertyId: Number(selectProperty.id),
              floorPlanName: unit.unitsInfo.unitTitle,
              tourListJson
            })
            return
          }
          const tourListJson = unit.unitsInfo.rooms.map(room => ({
            propertyId: Number(selectProperty.id),
            propertyName: selectProperty.name,
            unitId: unit.unitsInfo.unitId,
            unitName: unit.unitsInfo.unitName,
            roomId: room.id === -999 ? null : room.id, // whole unit is null
            roomName: room.name
          }))
          aiLeasingWantTourList.push({
            isColiving: true,
            prospectId: Number(prospectId),
            propertyId: Number(selectProperty.id),
            floorPlanName: unit.unitsInfo.unitTitle,
            tourListJson
          })
        })
      } else {
        selectedUnits.forEach(item => {
          if (item.unitsInfo.floorPlanIndex !== -1) {
            const tourListJson = item.unitsInfo.rooms.map(unit => ({
              propertyId: Number(selectProperty.id),
              propertyName: selectProperty.name,
              unitId: unit.id,
              unitName: unit.name
            }))
            aiLeasingWantTourList.push({
              prospectId: Number(prospectId),
              propertyId: Number(selectProperty.id),
              floorPlanName: item.unitsInfo.unitTitle,
              tourListJson
            })
          }
        })
      }
    }
    if (aiLeasingWantTourList.length === 0) return

    Statsig.logEvent(
      'schedule_tour_recommendation_co-livings',
      window.location.href,
      buildTourCoLivingsEventMetadata(prospectId, selectProperty, selectedUnits) as Record<string, string>
    )

    const input = { aiLeasingWantTourList }
    if (pageType === 'unit') {
      saveLeasingWantTour({
        variables: {
          input
        }
      })
      logs = Object.assign(logs, { units: selectValueObj, nextType: 'tourInfo' })

      Statsig.logEvent('schedule_tour_footer_next', window.location.href, logs)
    }
  }

  const onFooterBack = useCallback(() => {
    let nextType: PageTypeType = pageType

    if (pageType === 'tourInfo') {
      nextType = 'unit'
    }
    Statsig.logEvent('schedule_tour_footer_back', window.location.href, {
      pageType,
      nextType,
      questionnaireId,
      propertyId: selectProperty?.id || '',
      prospectId
    })
    if (!isMobile) {
      // pc allow link unit
      nextType = 'unit'
    }
    setPageType(nextType)
  }, [pageType])

  const filterOptions = useMemo(() => {
    if (selectProperty?.id) {
      return selectProperty.floorPlanModeUnit.map(floor => floor.bedroomOption)
    }

    return []
  }, [selectProperty?.id])

  const changeProperty = (property: ScheduleTourPropertyType) => {
    if (!property) return
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setSelectedPreferences([])
    setSelectedUnits(new Map())
    setSelectProperty(property)
    setSearchParams((prevSearchParams: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
      const newSearchParams = new URLSearchParams(prevSearchParams)
      newSearchParams.set('bedroomNum', 'ALL')

      return newSearchParams
    })
  }
  const checkLogInterval = () => {
    if (document.visibilityState === 'hidden' && logInterval) {
      clearInterval(logInterval)
    }
    if (document.visibilityState === 'visible') {
      saveUrlTtlLogApi()
      if (logInterval) clearInterval(logInterval)
      logInterval = setInterval(saveUrlTtlLogApi, 10000)
    }
  }
  const onPropertyChange = (id: string) => {
    const target = propertyList.find(item => item.id === id)
    if (!target) return
    changeProperty(target)
  }
  const onSelectUnitChange = (map: SelectedUnitsType) => {
    const changeCount = calcSelectedCount(map)

    if (changeCount <= MAX_SELECTED_UNIT) {
      const selectValueObj = [...map.entries()].reduce((obj, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = value
        return obj
      }, {} as Record<string, unknown>)
      Statsig.logEvent('schedule_tour_select_unit', window.location.href, {
        questionnaireId,
        prospectId,
        selectUnits: JSON.stringify(selectValueObj),
        pageType
      })
      setSelectedUnits(map)
    } else {
      setOpenErrorMsg(true)
      clearTimeout(errorMsgTimer)
      errorMsgTimer = setTimeout(() => {
        setOpenErrorMsg(false)
      }, 2000)
    }
  }

  const onClear = () => {
    setSelectedUnits(new Map())
  }

  const handleClose = () => {
    setFetchError({
      open: false,
      msg: ''
    })
  }

  useEffect(() => {
    if (logInterval) {
      clearInterval(logInterval)
    }
    saveUrlTtlLogApi()
    logInterval = setInterval(saveUrlTtlLogApi, 10000)
    document.addEventListener('visibilitychange', checkLogInterval)
    Statsig.logEvent('schedule_tour_PV_UV', window.location.href, {
      questionnaireId,
      propertyId: selectProperty?.id || ''
    })
    return () => {
      if (logInterval) clearInterval(logInterval)
      document.removeEventListener('visibilitychange', checkLogInterval)
    }
  }, [])

  const onWinScroll = () => {
    setFixedDom(window.scrollY > HEADER_HEIGHT)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', onWinScroll)
    return () => {
      window.removeEventListener('scroll', onWinScroll)
    }
  }, [])

  if (!questionnaireId) return null
  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" height={240} />
        <Skeleton height={200} className="w-[calc(100%-80px)] m-auto" />
        <Skeleton variant="rectangular" className="w-full fixed bottom-0 !h-[180px]" />
      </>
    )
  }

  return (
    <div className="w-full bg-[#F7F9FC] min-h-screen">
      {!isMobile ? (
        <div className="bg-[#111212] sticky z-20 top-0  px-[80px] h-[128px] flex items-center justify-start">
          <img src={Number(version) === 5 ? RentyLogo : Logo} className="h-[48px]" alt="tripalink" />
          <div className="bg-[white] mx-[64px] h-[40px] w-[2px] border-box" />
          <Typography className="!font-Averta6 !text-[36px] !text-[white]">
            {isCoLivingProperty ? 'Rental Preference Survey' : 'Schedule Tour'}
          </Typography>
        </div>
      ) : null}
      {!isMobile && openErrorMsg ? (
        <div className="fixed m-auto z-40 -translate-x-1/2 left-[50%] top-[148px] text-[white] text-[28px]  font-Averta4  rounded bg-[rgba(13,19,51,0.8)]  px-[32px] py-[20px] flex items-center">
          <img src={warning} alt="" className="mr-[8px]" />
          <span>You can only select up to 4 for your tour</span>
        </div>
      ) : null}
      <div className="w-full  min-h-screen lg:min-h-[calc(100vh-128px)] lg:w-1/2  m-auto bg-[white]">
        {pageType === 'unit' && (
          <div className="lg:min-h-[calc(100vh-251px)]">
            <div
              className="w-full h-[384px] lg:h-[522px] bg-gray-200"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: selectProperty
                  ? `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url('${selectProperty.headPicUrl}')`
                  : ''
              }}
            />
            <div
              className="w-[calc(100%-80px)] m-auto mt-[-80px] py-[32px] rounded-t-[24px] flex flex-col items-center"
              style={{ background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%)' }}
            >
              {/* Subheading for co-living */}
              {isCoLivingProperty && (
                <Typography className="!font-Averta4 !text-[24px] text-center flex items-center text-[#86909C]">
                  Discover our co-living space
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                      <BootstrapTooltip
                        onClose={handleTooltipClose}
                        open={tooltipOpen}
                        title={
                          <Typography className="font-Averta4 !text-[24px]">
                            Co-living spaces feature shared living rooms, kitchens, and additional communal areas.
                          </Typography>
                        }
                      >
                        <IconButton onClick={handleTooltipOpen}>
                          <QuestionCircle />
                        </IconButton>
                      </BootstrapTooltip>
                    </div>
                  </ClickAwayListener>
                </Typography>
              )}
              <ScheduleTourHeader
                fixedDom={fixedDom}
                theme="black"
                pageType={pageType}
                list={propertyList}
                showMenu={propertyList.length > 1}
                onChange={onPropertyChange}
                selected={selectProperty?.id ?? ''}
              />
              {selectProperty?.address && (
                <Typography className="!font-Averta4 !text-[28px] !text-center">{selectProperty.address}</Typography>
              )}
             
            </div>
            <Box className="py-[26px] px-[40px] lg:py-[48px] lg:px-[80px]">
              <div className="w-full flex items-center justify-between sticky lg:relative top-0 bg-white z-10 lg:z-1 mb-[16px]">
                <Typography className="!font-Averta6 !text-[32px]">Select your desired homes</Typography>
                <div className="flex items-center font-averta ">
                  {!isMobile && (
                    <span className="mr-[20px] text-[28px] font-[400]">
                      {selectedCount}/{MAX_SELECTED_UNIT}
                    </span>
                  )}

                  <button onClick={onClear} type="button" className="underline text-[28px]">
                    Clear
                  </button>
                </div>
              </div>
              <Box className="w-full overflow-x-auto pb-[12px]">
                <Stack direction="row" spacing={1}>
                  {filterOptions.map(filter => (
                    <Button
                      key={filter.key}
                      className="!font-Averta6 !text-[24px]"
                      onClick={onSearchUnits}
                      data-bd={filter.value}
                      sx={{
                        border: `1px solid ${filter.value === bedroomValue ? '#050A22' : '#E0E2EF'}`,
                        color: '#050A22',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        minWidth: '100px',
                        textTransform: 'none'
                      }}
                    >
                      {filter.label}
                    </Button>
                  ))}
                </Stack>
              </Box>
            </Box>
            <div className="w-full pb-[190px]">
              <ScheduleTourUnit
                isCoLivingProperty={isCoLivingProperty}
                questionnaireId={questionnaireId}
                selectedUnits={selectedUnits}
                propertyId={selectProperty?.id ?? ''}
                onchange={onSelectUnitChange}
                units={unitList}
              />
            </div>
          </div>
        )}

        {pageType !== 'tourInfo' ? (
          <ScheduleTourFooter
            onBack={onFooterBack}
            onClick={onSubmit}
            disabled={disableNext}
            type={pageType}
            maxUnit={MAX_SELECTED_UNIT}
            selectedCount={selectedCount}
          />
        ) : null}
        {pageType === 'tourInfo' && (
          <div className="pt-[104px] pb-[190px]">
            <ScheduleTourDetail onClickCard={onPropertyChange} propertyId={selectProperty?.id ?? ''} propertyList={propertyList} />
          </div>
        )}
      </div>
      <Snackbar open={fetchError.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {fetchError.msg}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ScheduleTour
