import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import ReserveCalendar from '../reserveCalendar'
import { QUERY_PROPERTY_TIME_ZONE } from '../../graphqls/queries'

interface Props {
  agent: {
    agentId: string
    agentName: string
  }
}

const DateForm = ({ agent }: Props) => {
  const [searchParams] = useSearchParams()
  const propertyId = searchParams.get('propertyId')

  const { data: { queryPropertyTimeZone } = {} } = useQuery(QUERY_PROPERTY_TIME_ZONE, {
    variables: {
      propertyId: Number(propertyId),
    },
  })

  const { displayText, timeZone } = queryPropertyTimeZone || {}

  const {
    control,
    formState: { errors },
    register,
  } = useFormContext()

  return (
    <Box className="flex flex-col gap-[64px] pb-[200px]">
      <Box className="flex flex-col gap-[16px]">
        <Box className="flex flex-col gap-[32px]">
          <Controller
            control={control}
            name="selectTime"
            rules={{ required: 'Please select the date', validate: value => (!value.time ? 'Please select the date' : undefined) }}
            render={({ field }) => (
              <>
                <ReserveCalendar adminUserId={agent.agentId} {...field} timeZoneText={displayText} timeZone={timeZone} />
                <FormHelperText
                  className="font-averta w-1/2"
                  sx={{
                    fontSize: 12,
                    color: '#E74F4F',
                  }}
                  id="component-error-text"
                >
                  {errors.selectTime?.message}
                </FormHelperText>
              </>
            )}
          />
          <Box className="flex flex-col gap-[16px]">
            <h4 className="font-averta font-semibold text-[32px]">Any other notes to tell tour host? </h4>
            <textarea
              {...register('customerNote')}
              className="w-full border-slate-200 border-[2px] rounded-[24px] resize-none font-averta text-[28px] px-[40px] py-[32px] outline-none h-[295px]"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DateForm
