import React, { useEffect, useMemo, useContext } from 'react'
import contextData from '../contextData'
import { QuestionItemProps, MODULES_CODE_ENUM } from '../types.d'

const CreditScoreHooks = (currentIndex: number) => {
  const { isPc, moduleList, currentStep, dataCollect, updateDataCollectSubmitValueFunction, mergeDataCollectFunction } =
    useContext(contextData)

  const currentData = moduleList[currentIndex]

  const scoreList = currentData?.question?.creditScore as []

  const creditScoreType = useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[0]?.value as string, [dataCollect]) || ''

  const showChildrenKey: string[] = useMemo(
    () =>
      (currentData?.question?.creditScore.find((item: QuestionItemProps) => item?.key === creditScoreType) || { childrenKey: [] })
        ?.childrenKey,
    [creditScoreType]
  )

  const isStudent = useMemo(() => {
    if (showChildrenKey?.includes(MODULES_CODE_ENUM?.IS_STUDENT)) {
      return dataCollect?.[currentIndex]?.submitValue?.[1]?.value
    }
    return false
  }, [dataCollect, showChildrenKey]) as boolean

  const cosigner = useMemo(() => {
    if (showChildrenKey?.includes(MODULES_CODE_ENUM?.COSIGNER)) {
      return dataCollect?.[currentIndex]?.submitValue?.[2]?.value
    }

    return null
  }, [dataCollect, showChildrenKey]) as boolean | null

  const isCanNext = useMemo(() => Boolean(creditScoreType), [creditScoreType])

  const displayData = useMemo(() => {
    const creditScoreTypeText =
      currentData?.question?.creditScore?.find((item: QuestionItemProps) => item?.key === creditScoreType)?.desc || ''
    const isStudentText = isStudent && showChildrenKey?.includes(MODULES_CODE_ENUM.IS_STUDENT) ? 'I am a student' : ''

    const cosignerText =
      cosigner !== null && showChildrenKey?.includes(MODULES_CODE_ENUM.COSIGNER) ? (cosigner ? 'With guarantor' : 'No guarantor') : ''

    const resultArray = [creditScoreTypeText, isStudentText, cosignerText].filter(Boolean)
    const result = resultArray.join('/')

    return result
  }, [creditScoreType, isStudent, cosigner, currentStep])

  const onChangeScore = (val: string) => {
    if (val === creditScoreType) return

    if (mergeDataCollectFunction) {
      const submitValue = [
        {
          name: dataCollect?.[currentIndex]?.submitValue?.[0]?.name,
          value: val
        },
        {
          name: dataCollect?.[currentIndex]?.submitValue?.[1]?.name,
          value: null
        },
        {
          name: dataCollect?.[currentIndex]?.submitValue?.[2]?.name,
          value: null
        }
      ]
      mergeDataCollectFunction(currentIndex, {
        submitValue
      })
    }
  }

  const onChangeIsStudent = (val: boolean) => {
    if (updateDataCollectSubmitValueFunction) {
      updateDataCollectSubmitValueFunction(currentIndex, 1, val)
    }
  }

  const onChangeGuarantee = (val: boolean) => {
    if (updateDataCollectSubmitValueFunction) {
      updateDataCollectSubmitValueFunction(currentIndex, 2, val)
    }
  }
  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData
      })
    }
  }, [isCanNext, displayData])

  return {
    isPc,
    scoreList,
    currentData,
    creditScoreType,
    isStudent,
    showChildrenKey,
    cosigner,
    onChangeScore,
    onChangeIsStudent,
    onChangeGuarantee
  }
}

export default CreditScoreHooks
