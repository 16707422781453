export interface ModuleItemProps {
  minBudget?: number
  maxBudget?: number
  index?: number
  code: string
  title?: string
  desc?: string
  question?: { [key: string]: any }
}
export interface CommonModuleProps {
  currentIndex: number
}

export interface QuestionItemProps {
  key: string
  value: string
  desc: string
  childrenKey?: string[]
}

export interface DataCollectProps {
  code: string
  submitValue: { name: string; value: string | number | [] }[]
  displayData: string
  isCanNext: boolean
  label: string
}

export interface FloorPlanHooksProps {
  currentIndex: number
}

export interface FloorPlanProps extends ModuleItemProps, FloorPlanHooksProps {
  selectBedroom: string[]
  selectBathroom: string[]
  onChange: (type: 'bedroom' | 'bathroom', list: string[]) => void
}

export interface BudgetProps extends ModuleItemProps {
  budget: [number, number]
  onChange: (budget: [number, number]) => void
}

export interface MoveDateProps extends ModuleItemProps {
  moveInDate: { from: string; to: string }
  onChange: (moveInDate: { from: string; to: string }) => void
}

export interface CreditScoreProps extends ModuleItemProps {
  creditNumber: number
  isStudent: boolean
  onChangeIsStudent: (isStudent: boolean) => void
  onChangeScore: (creditScore: string) => void
  creditScore: string
  guarantee: string
  onChangeGuarantee: (guarantee: string) => void
  isPC?: boolean
}

export interface StayPlaningProps extends ModuleItemProps {
  leaseTerm
  onChange: (leaseTerm: number) => void
}

export interface LeaseTermProps extends ModuleItemProps {
  leaseTerm: number
  onChange: (leaseTerm: number) => void
}

export interface ParkingProps extends ModuleItemProps {
  leaseTerm: number
  onChange: (leaseTerm: number) => void
}

export enum MODULES_CODE_ENUM {
  FLOOR_PLAN = 'floorPlan',
  BUDGET = 'budget',
  MOVE_IN_DATE = 'moveInDate',
  CREDIT_SCORE = 'creditScore',
  STAY_PLANING = 'stayPlaning',
  LEASE_TERM = 'leaseTerm',
  PARKING = 'parkingSpot',
  IS_STUDENT = 'isStudent',
  COSIGNER = 'cosigner',
  CUSTOM_MONTHS = 'STAY_LEASE_TERM'
}
