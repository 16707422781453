import { isFinite } from 'lodash'
/**
 * 解析浏览器地址栏 url 的 search 的某个 name 的值
 * @param   {String}
 * @return  {String}
 */

export const queryURL = (name: string) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}

/**
 * 数组内查询
 * @param   {array}     array
 * @param   {String}    id
 * @param   {String}    keyAlias
 * @return  {Array}
 */
export const queryArray = (array: [], key: string, keyAlias = 'key') => {
  if (!(array instanceof Array)) {
    return null
  }
  const item = array.filter(_ => _[keyAlias] === key)
  if (item.length) {
    return item[0]
  }
  return null
}

/**
 * 截取指定长度字符串
 * @author ronffy
 */

export default function sliceString(str: string, start: number, end: number) {
  const arr = str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g)
  if (!arr) {
    return ''
  }
  return arr.slice(start, end).join('')
}

/**
 * @format {date:2022-08-20}
 */
export const dateConverter = ({ date }: { date: string }) => {
  if (!date) return ''
  const newDate = new Date(date.replace(/-/g, '/'))
  const chinaDate = newDate.toDateString()
  const chinaDateArray = chinaDate.split(' ')
  const displayDate = `${chinaDateArray[1]} ${chinaDateArray[2]}, ${chinaDateArray[3]}`
  return displayDate
}

/**
 * 获取欧美价格字符串
 * @param value price
 * @returns
 */
export const getCurrency = (value: string) => {
  const numberValue = parseFloat(value)
  if (!isFinite(numberValue)) {
    return ''
  }

  return numberValue.toLocaleString('en-US')
}

export const initPageConfig = (version: number) => {
   const defaultConfig = {
    title: 'Renty Survey',
    icon: 'https://tripalink-public.s3-accelerate.amazonaws.com/web%2Fdfbc289a9e4bd96c.png'
  }
  const versionConfig = {
    5: {
      title: 'Renty Survey',
      icon: 'https://tripalink-public.s3-accelerate.amazonaws.com/web%2Fdfbc289a9e4bd96c.png'
    }
  }[version]

  document.title = (versionConfig || defaultConfig)?.title || ''
  const favicon: any = document.querySelector('link[rel="icon"]')
  if (favicon && favicon?.href) {
    favicon.href = (versionConfig || defaultConfig)?.icon
  }
}
