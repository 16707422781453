import { Typography, Box } from '@mui/material'
import React from 'react'
import moment from 'moment'
import { RangePicker as ReactRangePicker } from 'react-trip-date'
import { CommonModuleProps } from './types.d'
import indexHooks from './hooks/index'

const { moveInDateHooks } = indexHooks

const MoveDate = ({ currentIndex }: CommonModuleProps) => {
  const { moveInDate, currentData, onChange } = moveInDateHooks(currentIndex)

  return (
    <>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
        <Typography className="!font-Averta4 !text-[28px] !mt-[16px] text-[#75798D]">{currentData?.desc}</Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[32px] min-h-[756px]">
        <ReactRangePicker
          components={{
            header: {
              format: `MMMM YYYY`
            }
          }}
          numberOfMonths={1}
          autoResponsive={false}
          initialMonthAndYear={moveInDate.from || moment()?.format('YYYY-MM-DD')}
          onChange={onChange}
          disabledBeforeToday
          selectedDays={{ from: moveInDate.from, to: moveInDate.from }}
        />
      </Box>
    </>
  )
}

export default MoveDate
