import { Box, Typography } from '@mui/material'
import React, { FC, PropsWithChildren, useLayoutEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Logo from '../../../../assets/imgs/rentyWhiteIcon.svg'
import CheckIcon from '../../../../assets/imgs/checkIcon.svg'
import SubmitResult from '../submitResult'

interface PCV5props {
  moduleList: { key: string; component: JSX.Element }[]
  disabled: boolean
  loading: boolean
  onSubmit: () => void
  isSubmit: boolean
  questionnaireId: string
}

const PCV5: FC<PCV5props> = props => {
  const { moduleList, disabled, loading, onSubmit, isSubmit, questionnaireId } = props
  useLayoutEffect(() => {
    document.body.style.backgroundColor = '#f7f9fc'
  }, [])
  return (
    <div>
      <div className="bg-[#111212] sticky z-20 top-0  px-[80px] h-[128px] flex items-center justify-start">
        <img src={Logo} className="h-[48px] text-[red]" alt="tripalink" />
        <div className="bg-[white] mx-[64px] h-[40px] w-[2px] border-box" />
        <Typography className="!font-Averta6 !text-[36px] !text-[white]">Rental Preference Survey</Typography>
      </div>
      <div className="min-h-[calc(100vh-128px)] w-1/2 m-auto bg-white px-[200px] pt-[96px] pb-[80px]">
        {isSubmit ? (
          <Box className="pt-[400px]">
            <SubmitResult questionnaireId={questionnaireId} isPC />
          </Box>
        ) : (
          <>
            <div className="p-[32px] bg-[#F7F9FC] rounded flex  items-start">
              <img src={CheckIcon} className="w-[32px]  mr-[16px] mt-[4px]" alt="" />
              <span className="font-[400] text-[28px]">
                Please answer the following 5 questions so we can find the right home for your needs. All questions are required.
              </span>
            </div>
            {moduleList.map((module, index) => (
              <ItemContainer key={module.key} index={index + 1}>
                {module.component}
              </ItemContainer>
            ))}
            <LoadingButton
              className={`${
                disabled || loading ? '!bg-[#e0e0e0] cursor-not-allowed' : '!bg-[#E74F4F] cursor-pointer'
              }  !rounded-[24px] !px-[70px] !py-[24px]`}
              onClick={onSubmit}
              loading={loading}
              disabled={disabled || loading}
            >
              <Typography className="capitalize !font-Averta6 !text-[32px] text-white">Submit</Typography>
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  )
}

// eslint-disable-next-line react/require-default-props
const ItemContainer: FC<PropsWithChildren<{ index: number }>> = props => {
  const { children, index } = props
  return (
    <div className="pb-[146px] relative">
      <Typography className="!absolute top-[32px] left-[-18px] !text-[36px] !font-Averta6">{index}. </Typography>
      {children}
    </div>
  )
}
export default PCV5
