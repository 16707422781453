import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, LinearProgress, Typography } from '@mui/material'
import React, { FC, useMemo, useContext } from 'react'
import contextData from './contextData'

const FormFooter = () => {
  const { currentStep, allStep, isPc, saveLoading: loading, isCanNext, onSubmit, canSubmit } = useContext(contextData)
  const isLastStep = useMemo(() => currentStep === allStep - 1, [currentStep, allStep])
  const disabled = useMemo(() => {
    if (isPc) return !canSubmit
    return isLastStep ? !isCanNext || !canSubmit : !isCanNext
  }, [isLastStep, isCanNext, canSubmit])

  const onClick = () => {
    if (disabled) return
    if (onSubmit) {
      onSubmit({
        submit: isLastStep
      })
    }
  }
  return (
    <Box className="fixed flex justify-between items-center w-full bottom-0 py-[32px] px-[40px] bg-white shadow-footerShadow">
      <Box className="flex flex-col justify-start items-start">
        <span className="text-[36px] font-Averta7">
          {currentStep + 1}/{allStep}
        </span>
        <LinearProgress className="w-[340px] my-[20px]" variant="determinate" value={Number((currentStep * 100) / allStep)} />
      </Box>
      <LoadingButton
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        className="!rounded-[24px] !py-[24px] !px-[80px]"
        variant="contained"
      >
        <Typography className="!text-[32px] font-Averta6 font-semibold  capitalize">{isLastStep ? 'Submit' : 'Next'}</Typography>
      </LoadingButton>
    </Box>
  )
}

export default FormFooter
