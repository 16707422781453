import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { trim } from 'lodash'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useLazyQuery, useMutation } from '@apollo/client'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { Skeleton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import TourCancelBaseInfoCard from '../tourCancelBaseInfoCard'

import { TOUR_CANCEL } from '../../graphqls/mutations'

import TourBaseInfoCard from '../tourBaseInfoCard'
import LogoIcon from '../../icons/tripalinkTourV2/logo'
import themeConfig from '../theme'
import { GET_TOUR_BY_ID } from '../../graphqls/queries'
import TourCompleteImg from '../../assets/imgs/tourComplete.png'

const IMG_URL = process.env.REACT_APP_IMG_URL
const TourDetailImg = `${IMG_URL}0674ca3028875846.png`
const TourCancelImg = `${IMG_URL}59c95d51933c34c0.png`

const TourHeaderImg: { [key: string]: string } = {
  SCHEDULED: TourDetailImg,
  CANCELED: TourCancelImg,
  FINISHED: TourCompleteImg,
}

const TourStatusList: { [key: string]: string } = {
  SCHEDULED: 'scheduled',
  CANCELED: 'canceled',
  FINISHED: 'completed',
}

const TourCancelReasonList: string[] = ['Time conflict', 'No longer interested in this property', 'No longer available ', 'Other reason']

const TourDetail = () => {
  const [searchParams] = useSearchParams()
  const theme = createTheme(themeConfig)
  const { tourId } = useParams()
  const navigateTo = useNavigate()
  const [isShowCancelReason, setShowCancelReason] = useState(false)
  const customerInfo = searchParams.get('customerInfo') || ''

  const [activeCancelReason, setCancelReason] = useState('')
  const [cancelReasonNote, setCancelReasonNote] = useState('')

  const { firstName = '', lastName = '', tourType = '' } = customerInfo ? JSON.parse(customerInfo) : {}

  const [getTourInfo, { data: { getTourById = '' } = {}, loading }] = useLazyQuery(GET_TOUR_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      tourId: Number(tourId),
    },
  })

  const { data } = getTourById || {}
  const { status, propertyId, prospectId, tourAgentId, eventId, prospectName, prospectEmail, prospectPhone } = data || {}

  useEffect(() => {
    getTourInfo()
  }, [])

  const onRescheduleBtn = () => {
    navigateTo(
      `/tripalinkTourV2?isReschedule=1&propertyId=${propertyId}&source=ai-leasing&prospectId=${prospectId}&email=${prospectEmail}&firstName=${firstName}&lastName=${lastName}&phoneNumber=${prospectPhone}&tourType=${tourType}`,
    )
  }

  const [cancelTour, { loading: cancelTourLoading }] = useMutation(TOUR_CANCEL, {
    onCompleted: res => {
      const { cancelTour = {} } = res || {}
      const { ret } = cancelTour || {}

      if (ret) {
        setShowCancelReason(false)
        getTourInfo()
      }
    },
  })

  const onCancelTour = () => {
    cancelTour({
      variables: {
        input: {
          tourId: Number(tourId),
          eventId,
          cancelReason: activeCancelReason,
          cancelNote: activeCancelReason === TourCancelReasonList[3] ? cancelReasonNote : '',
          userId: tourAgentId,
          cancelSource: 'USER',
        },
      },
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className="flex flex-col gap-[64px] pb-[200px] px-[40px] pt-[32px] max-w-[1000px] mx-auto">
        <div
          className="absolute left-0 top-0 h-[920px] w-full z-0 pointer-events-none"
          style={{
            background:
              'linear-gradient(180deg, rgba(255, 237, 237, 0.70) 0%, rgba(234, 236, 255, 0.1) 57.49%, rgba(253, 252, 240, 0.00) 100%)',
          }}
        />
        <Box className="flex flex-col gap-[64px] relative">
          <LogoIcon />
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box className="flex flex-col gap-[48px] items-center">
              <h3 className="font-bold font-minion text-[48px] capitalize">{`Hi ${trim(prospectName)},`}</h3>
              <Box className="flex flex-col items-center justify-center gap-[24px]">
                <img className="w-[160px] h-[160px]" src={TourHeaderImg[status]} alt="tour detial" />
                <Box className="flex flex-col gap-[16px] text-center">
                  <h3 className="font-averta font-bold text-[48px] text-default">Your tour is {TourStatusList[status]}</h3>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <TourBaseInfoCard dataSource={data} loading={loading} />
        {!loading && status === 'SCHEDULED' && (
          <div className="flex text-center font-semibold">
            <div
              className="rounded-[24px] w-1/2 btn border !text-[32px] py-[24px] cursor-pointer"
              onClick={() => setShowCancelReason(true)}
            >
              Cancel
            </div>
            <div className="rounded-[24px] w-1/2 ml-[16px] border btn !text-[32px] py-[24px] cursor-pointer" onClick={onRescheduleBtn}>
              Reschedule
            </div>
          </div>
        )}

        <TourCancelBaseInfoCard dataSource={data} loading={loading} />

        <Dialog open={isShowCancelReason} className="mobile-box reason-list-dialog-box" onClose={() => setShowCancelReason(false)}>
          <div className="text-center mt-[16px] mb-[16px] w-[44px] mx-auto">
            <div className="w-[44px] h-[4px] rounded-full bg-[#C7CBD1]" />
          </div>

          <p className="text-[#050A22] text-[32px] mt-[40px] font-semibold mb-[48px]">Reason for cancellation</p>
          <div>
            {TourCancelReasonList.map(item => (
              <div
                key={item}
                onClick={() => setCancelReason(item)}
                className={`${
                  activeCancelReason === item ? '!border-[2px] !border-[#050A22] !bg-[#F7F9FC]' : ''
                } text-[28px] h-[96px] leading-[96px] font-normal rounded-[200px] border border-slate-200 pl-[48px] mb-[24px] cursor-pointer`}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="other-reason-note h-[200px]">
            {activeCancelReason === TourCancelReasonList[3] && (
              <textarea
                className="reason-text-area w-full border border-gray-550 rounded-[24px] resize-none text-[24px] px-[40px] py-[18px]"
                placeholder="Please specify the reason (optional)"
                onChange={e => setCancelReasonNote(e.currentTarget.value)}
              />
            )}
          </div>
          <div className="flex text-center font-semibold mb-[32px]">
            <div
              className="rounded-[24px] w-1/2 btn !text-[32px] h-[96px] leading-[96px] cursor-pointer"
              onClick={() => setShowCancelReason(false)}
            >
              Back
            </div>
            <LoadingButton
              className={`!ml-[16px] ${
                activeCancelReason && !cancelTourLoading ? '!bg-red-450 !text-[#fff]' : 'bg-[#F4F5FA] text-[#A6A9BB]'
              }`}
              onClick={onCancelTour}
              loading={cancelTourLoading}
              disabled={!activeCancelReason}
            >
              Cancel Tour
            </LoadingButton>
          </div>
        </Dialog>
      </Box>
    </ThemeProvider>
  )
}

export default TourDetail
