import { Typography, Box, Slider } from '@mui/material'
import React, { useState, useRef } from 'react'
import { CommonModuleProps } from './types.d'
import indexHooks from './hooks/index'

const { leaseTermHooks } = indexHooks

const LeaseTerm = ({ currentIndex }: CommonModuleProps) => {
  const { leaseTerm, onChange, currentData } = leaseTermHooks(currentIndex)
  const [open, setOpen] = useState(false)
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const sliderChange = (_: unknown, value: number | number[]) => {
    if (typeof value === 'number') onChange(value)
  }

  return (
    <>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Box
          ref={anchorEl}
          aria-describedby="month-popover"
          onClick={() => {
            setOpen(!open)
          }}
          className="flex bg-[white] rounded-[24px] py-[10px] px-[32px] justify-between items-center"
        >
          <div className="flex items-start flex-col">
            <span className="text-[#75798D] text-[24px] font-Averta4 mb-[8px]">Lease Term (Month)</span>
            <span className="text-[#050A22] text-[32px] font-Averta4">
              {leaseTerm} {leaseTerm === 1 ? 'month' : 'months'}
            </span>
          </div>
        </Box>
        <Slider
          className="mt-[24px]"
          onChange={sliderChange}
          sx={{
            '& .MuiSlider-rail': {
              bgcolor: '#E0E2EF'
            }
          }}
          step={1}
          value={leaseTerm}
          max={currentData?.question?.leaseTerm?.maxMonth}
          min={currentData?.question?.leaseTerm?.minMonth}
          color="secondary"
        />
      </Box>
    </>
  )
}

export default LeaseTerm
