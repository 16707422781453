const floorplanStrToArr = (floorPlan: string) => {
  const result = [[], []]
  let newData = floorPlan.replaceAll('6+', "'6+'")
  newData = floorPlan.replaceAll('Studio', "'Studio'")
  const originDataList = newData.split('],') || ['[]', 'NULL']
  const bedroomOriginData = `${originDataList[0]}]` || '[]'
  const bathroomOriginData = originDataList[1] || 'NULL'
  if (bedroomOriginData) result[0] = JSON.parse(bedroomOriginData)
  if (bathroomOriginData && bathroomOriginData !== 'NULL') result[1] = JSON.parse(bathroomOriginData)
  return result
}
const floorPlanArrToStr = (floorPlan: [string[], string[]]) => {
  const bedrooms = floorPlan[0]
  const bathrooms = floorPlan[1]
  const bedroomsStr = bedrooms.length > 0 ? JSON.stringify(bedrooms) : 'NULL'
  const bathroomsStr = bathrooms.length > 0 ? JSON.stringify(bathrooms) : 'NULL'
  return `${bedroomsStr},${bathroomsStr}`
}

const budgetStrToArr = (budget: string) => (budget ? budget.split(',').map(Number) : [0, 0]) as [number, number]
const budgetArrToStr = (budget: [number, number]) => budget.toString()

const moveInDateStrToArr = (moveInDate: string) => {
  if (moveInDate.includes(',')) {
    return {
      from: moveInDate.split(',')[0],
      to: moveInDate.split(',')[1],
    }
  }
  return {
    from: moveInDate,
    to: '',
  }
}
const moveInDateArrToStr = (moveInDate: { from: string; to: string }) => `${moveInDate.from || ''},${moveInDate.to || ''}`

const leaseTermStrToArr = (data: string) => Number(data)
const leaseTermArrToStr = (data: number) => data.toString()

const creditScoreStrToArr = (creditScore: string) => {
  const result = { creditScore: '', isStudent: false }
  if (creditScore.includes('1') && creditScore.includes(',')) {
    const [credit, isStudent] = creditScore.split(',')
    result.creditScore = credit
    result.isStudent = isStudent !== 'false'
  }
  return result
}
const creditScoreArrToStr = (result: { creditScore: string; isStudent: boolean }) =>
  `${result.creditScore === '1' ? `${result.creditScore},${result.isStudent}` : result.creditScore}`

const guaranteeStrToArr = (guarantee: string) => {
  if (guarantee === 'NULL') return ''
  return guarantee
}
const guaranteeArrToStr = (result: { creditScore: string; guarantee: string }) => {
  if (result.creditScore === '0' || result.guarantee === '') return 'NULL'
  return result.guarantee
}

// api order returns: floor-plan budget moveInDate leadsTerm creditScore guarantee
const arrToStrList = [floorPlanArrToStr, budgetArrToStr, moveInDateArrToStr, leaseTermArrToStr, creditScoreArrToStr, guaranteeArrToStr]
export const changeAnswerArrToStr = (answers: any[]) => {
  const result: string[] = []
  answers.forEach((item, index) => {
    result.push(arrToStrList[index](item))
  })
  return result
}

export const changeAnswerStrToArr = (answers: string[]) => {
  const result: {
    selectBedroom?: never[]
    selectBathroom?: never[]
    budget?: [number, number]
    leaseTerm?: number
    moveInDate?: { from: string; to: string }
    creditScore?: string
    isStudent?: boolean
    guarantee?: string
  } = {}
  answers.forEach((item, index) => {
    switch (index) {
      case 0: {
        const temp = floorplanStrToArr(item)
        const [bed, bath] = temp
        result.selectBedroom = bed
        result.selectBathroom = bath
        break
      }
      case 1: {
        result.budget = budgetStrToArr(item)
        break
      }
      case 2: {
        result.moveInDate = moveInDateStrToArr(item)
        break
      }
      case 3: {
        result.leaseTerm = leaseTermStrToArr(item)
        break
      }
      case 4: {
        const temp = creditScoreStrToArr(item)
        result.creditScore = temp.creditScore
        result.isStudent = temp.isStudent
        break
      }
      case 5: {
        result.guarantee = guaranteeStrToArr(item)
        break
      }
      default:
        break
    }
  })
  return result
}

export default {}
