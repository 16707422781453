import React, { useState, useRef, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Statsig } from 'statsig-react'
import Box from '@mui/material/Box'
import StepItemCom from './stepItemCom'
import ButtonBar from './buttonBar'
import LogoIcon from '../../icons/tripalinkTourV2/logo'
import InfoIcon from '../../icons/tripalinkTourV2/info'
import CalendarIcon from '../../icons/tripalinkTourV2/calendar'
import themeConfig from '../theme'
import { ADD_TOUR_V2 } from '../../graphqls/mutations'
import {
  QUERY_PROPERTY_TOUR_AGENT,
  QUERY_PROPERTY_TIME_ZONE,
  QUERY_PROSPECT_ID_BY_UNIQ_ID,
  QUERY_TOUR_LIST_BY_PROSPECT_ID,
} from '../../graphqls/queries'

const stepContentText = [
  {
    title: 'Tour Schedule',
    desc: '',
    icon: <InfoIcon />,
  },
  {
    title: 'Select the date and time',
    desc: 'Here is the available time based on your choice. If none of the time fits your requirement, please choose a similar time and leave the note. The agent will reach out to you ASAP.',
    icon: <CalendarIcon />,
  },
]

const Index = () => {
  const [searchParams] = useSearchParams()
  const navigateTo = useNavigate()
  const prospectId = searchParams.get('prospectId')
  const propertyId = searchParams.get('propertyId')
  const uniqId = searchParams.get('uniqId')
  const isReschedule = searchParams.get('isReschedule')

  const theme = createTheme(themeConfig)
  const methods = useForm({
    defaultValues: {
      propertyName: '',
      units: [],
      tourType: '',
    },
  })

  const [step, setStep] = useState(0)
  const [customerInfo, setCustomerInfo] = useState('')
  const agentInfoRef = useRef({
    agentId: '',
    agentName: '',
  })
  const containerRef = useRef<HTMLElement>()

  const { timeZone: currentTimeZone } = Intl.DateTimeFormat().resolvedOptions()

  const [queryTourListByProspectIdApi] = useLazyQuery(QUERY_TOUR_LIST_BY_PROSPECT_ID, {
    fetchPolicy: 'network-only',
    variables: {
      prospectId,
    },
    onCompleted(res) {
      const { queryTourListByProspectId } = res || {}
      const { data } = queryTourListByProspectId || {}
      const curTourList = Array.isArray(data) ? data : []
      const currTour = curTourList.find(item => propertyId && String(item.propertyId) === String(propertyId))
      const { id } = currTour || {}
      // If there is existing tour information, enter the tour state directly.
      if (id) {
        navigateTo(`/tourDetail/${id}`)
      }
    },
  })

  useEffect(() => {
    if (prospectId && !uniqId) {
      getPropertyTourAgent()
    }

    if (isReschedule && document.getElementById('choose-tour-unit')) {
      document.getElementById('choose-tour-unit')?.scrollIntoView({
        behavior: 'smooth',
      })
    }

    if (prospectId && propertyId && !isReschedule) {
      queryTourListByProspectIdApi()
    }
  }, [prospectId])

  const [saveTourInfo, { loading: saveLoading }] = useMutation(ADD_TOUR_V2, {
    onCompleted({ addTourV2 }) {
      const { tourId } = addTourV2 || {}
      navigateTo(`/tourDetail/${tourId}?customerInfo=${customerInfo}`)
    },
  })

  const { data: { queryProspectIdByUniqId: { prospectId: uidToProspectId = '' } = {} } = {} } = useQuery(QUERY_PROSPECT_ID_BY_UNIQ_ID, {
    variables: {
      uniqId,
    },
    onCompleted() {
      getPropertyTourAgent()
    },
    skip: !uniqId,
  })

  const { data: { queryPropertyTimeZone = {} } = {} } = useQuery(QUERY_PROPERTY_TIME_ZONE, {
    variables: {
      propertyId: Number(propertyId),
    },
  })

  const { timeZone } = queryPropertyTimeZone || {}

  const [getPropertyTourAgent] = useLazyQuery(QUERY_PROPERTY_TOUR_AGENT, {
    variables: {
      propertyId: Number(propertyId),
      prospectId: Number(prospectId || uidToProspectId),
    },
    onCompleted: agentListData => {
      const { queryPropertyTourAgent = {} } = agentListData || {}
      const { adminUsers = [] } = queryPropertyTourAgent || {}
      const { adminUserId: defaultAgentId, adminUserName: agentName } = adminUsers[0] || {}

      agentInfoRef.current = {
        agentId: defaultAgentId,
        agentName,
      }
    },
  })

  const onSubmit = (value: any) => {
    if (step === 0) {
      setStep(step + 1)
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
      return
    }
    const { propertyName, email, firstName, lastName, phoneNumber, region, selectTime, tourType, units, customerNote } = value || {}
    const { time, startSecond } = selectTime || {}
    const { agentId, agentName } = agentInfoRef.current

    setCustomerInfo(
      JSON.stringify({
        firstName,
        lastName,
        tourType,
      }),
    )

    saveTourInfo({
      variables: {
        input: {
          propertyId: Number(propertyId),
          prospectId: Number(prospectId || uidToProspectId),
          isRetrospective: false,
          propertyName,
          units: units.map((item: string) => JSON.parse(item).id),
          tourAgentId: agentId,
          tourAgentName: agentName,
          time,
          timezone: currentTimeZone,
          timeStartSecond: Number(startSecond),
          tourType,
          customerNote,
          customerFirstName: firstName,
          customerLastName: lastName,
          customerEmail: email,
          // customerPhone: `${region} ${phoneNumber}`,
          customerPhone: phoneNumber,
          source: 'USER',
          propertyTimeZone: timeZone,
        },
      },
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        ref={containerRef}
        className="px-[40px] pt-[32px] text-default flex flex-col gap-[64px] w-full max-w-[1000px] mx-auto overflow-hidden"
      >
        <div
          className="absolute left-0 top-0 h-[920px] w-full z-0 pointer-events-none"
          style={{
            background:
              'linear-gradient(180deg, rgba(255, 237, 237, 0.70) 0%, rgba(234, 236, 255, 0.1) 57.49%, rgba(253, 252, 240, 0.00) 100%)',
          }}
        />
        <Box className="flex flex-col gap-[64px] relative">
          <LogoIcon />
          <Box className="flex flex-col items-center justify-center gap-[24px]">
            {stepContentText[step].icon}
            <Box className="flex flex-col gap-[16px] text-center">
              <h3 className="font-averta font-bold text-[48px]">{stepContentText[step].title}</h3>
              <p
                className="text-zinc-400 font-averta"
                style={{
                  fontSize: 14,
                }}
              >
                {stepContentText[step].desc}
              </p>
            </Box>
          </Box>
        </Box>
        <FormProvider {...methods}>
          <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} className="relative">
            <StepItemCom step={step} agent={agentInfoRef.current} prospectId={prospectId || uidToProspectId} />
            <ButtonBar step={step} setStep={setStep} loading={saveLoading} disabled={!methods.formState.isValid} />
          </Box>
        </FormProvider>
      </Box>
    </ThemeProvider>
  )
}

export default Index
