import React, { useEffect, useState } from 'react'
import { slice } from 'lodash'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { useFormContext, Controller } from 'react-hook-form'
import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import AddressIcon from '../../icons/tripalinkTourV2/address'
import CheckOutlined from '../../icons/tripalinkTourV2/checkOutlined'
import { QUERY_INIT_TOUR_INFO_FOR_CUSTOMER, FIND_PROPERTIES } from '../../graphqls/queries'

interface TourType {
  name: string
  key: string
}

interface ImageType {
  id: string
  alt: string | undefined
  picSrcUrl: string
}

interface UnitType {
  aliasName: string
  id: string
  name: string
}

interface Props {
  prospectId: string | number
}

const PropertyForm = ({ prospectId }: Props) => {
  const [searchParams] = useSearchParams()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const propertyId = searchParams.get('propertyId')
  const isReschedule = searchParams.get('isReschedule')
  const currEmail = searchParams.get('email')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const phoneNumber = searchParams.get('phoneNumber')
  const currTourType = searchParams.get('tourType')
  // The + sign in the url is escaped into a space
  const currPhoneNumber = phoneNumber ? phoneNumber.replaceAll(' ', '+') : ''

  const [tourTypeList, setTourTypeList] = useState<TourType[]>([])
  const [unitList, setUnitList] = useState([])
  const [imageList, setImageList] = useState([])
  const [address, setAddress] = useState([])

  const email = watch('email')
  const watchTourType = watch('tourType')

  const [getInitTourInfo] = useLazyQuery(QUERY_INIT_TOUR_INFO_FOR_CUSTOMER, {
    variables: {
      propertyId: Number(propertyId),
      prospectId: Number(prospectId),
    },
    onCompleted({ queryInitTourInfoForCustomer }) {
      const { data } = queryInitTourInfoForCustomer || {}
      const { prospectEmail, prospectFirstName, prospectLastName, prospectPhone, tourType } = data || {}
      const tourTypes = tourType || []
      setTourTypeList(tourTypes || [])

      if (!email) {
        // The information filled in by the last user shall prevail.
        setValue('firstName', firstName || prospectFirstName)
        setValue('lastName', lastName || prospectLastName)
        setValue('email', currEmail || prospectEmail)
        setValue('phoneNumber', currPhoneNumber || prospectPhone)
      }
      setValue('tourType', currTourType || tourTypes[0].key)
    },
    // skip: !propertyId || !prospectId,
  })

  useEffect(() => {
    if (propertyId && prospectId) {
      getInitTourInfo()
    }
  }, [prospectId])

  const { loading } = useQuery(FIND_PROPERTIES, {
    variables: {
      propertyId,
      pageNum: 1,
      pageSize: 10,
    },
    onCompleted({ findProperties }) {
      const { edges } = findProperties || {}
      const [edge] = edges || []
      const { node } = edge || {}
      const { property } = node || {}
      const { aliasName, units, pics, address, name } = property || {}
      const { fullAddress } = address || {}
      setAddress(fullAddress)
      if (pics.length) {
        setImageList(slice(pics, 0, 2))
      }
      setValue('propertyName', aliasName || name)
      setUnitList(units)
    },
  })

  return (
    <Box className="flex flex-col gap-[64px] pb-[200px]">
      {/* My contact information */}
      <Box className="flex flex-col gap-[16px]">
        <h5 className="text-[32px] font-averta font-semibold after:content-['*'] after:text-red-450 after:ml-[4px]">
          My contact information
        </h5>
        <Box className="flex flex-col gap-[32px]">
          <Box>
            <Box className="flex gap-[16px]">
              <input
                className="outline-none w-1/2 text-[32px] font-averta border-[2px] border-slate-200 rounded-[24px] px-[40px] focus:border-default py-[32px]"
                {...register('firstName', {
                  required: 'First name is required',
                })}
                placeholder="First name"
                disabled={isReschedule}
                style={{ color: isReschedule ? 'rgba(0, 0, 0, 0.38)' : '#050A22' }}
              />
              <input
                className="outline-none w-1/2 text-[32px] font-averta border-[2px] border-slate-200 rounded-[24px] px-[40px] py-[32px] focus:border-default"
                {...register('lastName', { required: 'lastName is required' })}
                placeholder="last name"
                disabled={isReschedule}
                style={{ color: isReschedule ? 'rgba(0, 0, 0, 0.38)' : '#050A22' }}
              />
              {/* <OutlinedInput
                className="outline-none"
                {...register('firstName', {
                  required: 'First name is required',
                })}
                placeholder="First name"
                sx={{
                  fontSize: 16,
                  '& .MuiOutlinedInput-notchedOutline': {
                    // border: 'none',
                    borderColor: '#ECEEF6',
                  },
                }}
              /> */}

              {/* <OutlinedInput {...register('lastName', { required: 'lastName is required' })} placeholder="last name" /> */}
            </Box>
            <Box className="flex">
              <FormHelperText
                className="font-averta w-1/2"
                sx={{
                  fontSize: 12,
                  color: '#E74F4F',
                }}
                id="component-error-text"
              >
                {errors.firstName?.message}
              </FormHelperText>
              <FormHelperText
                className="font-averta w-1/2"
                sx={{
                  fontSize: 12,
                  color: '#E74F4F',
                }}
                id="component-error-text"
              >
                {errors.lastName?.message}
              </FormHelperText>
            </Box>
          </Box>
          <Box>
            <input
              type="email"
              className="outline-none w-full text-[32px] font-averta border-[2px] border-slate-200 rounded-[24px] px-[40px] focus:border-default py-[32px]"
              {...register('email', {
                required: 'Email address is required',
              })}
              placeholder="Email address"
              disabled={isReschedule}
              style={{ color: isReschedule ? 'rgba(0, 0, 0, 0.38)' : '#050A22' }}
            />
            {/* <OutlinedInput
              className="w-full"
              type="email"
              {...register('email', {
                required: 'Email address is required',
              })}
              placeholder="Email address"
            /> */}
            <FormHelperText
              className="font-averta w-1/2"
              sx={{
                fontSize: 12,
                color: '#E74F4F',
              }}
              id="component-error-text"
            >
              {errors.email?.message}
            </FormHelperText>
          </Box>
          <Box>
            <Box className="flex relative border-[2px] border-slate-200 rounded-[24px]">
              {/* <Select
                {...register('region')}
                sx={{
                  fontSize: 16,
                  '.MuiSelect-select': {},
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                defaultValue="+1"
                displayEmpty
              >
                <MenuItem value="+1">+ 1</MenuItem>
                <MenuItem value="+86">+ 86</MenuItem>
              </Select> */}
              <OutlinedInput
                className="w-full"
                type="tel"
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                })}
                placeholder="Phone number"
                sx={{
                  fontSize: 16,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                disabled={isReschedule}
              />
            </Box>
            <FormHelperText
              className="font-averta w-1/2"
              sx={{
                fontSize: 12,
                color: '#E74F4F',
              }}
              id="component-error-text"
            >
              {errors.phoneNumber?.message}
            </FormHelperText>
          </Box>
        </Box>
      </Box>
      {/* The property I want to tour */}
      <Box className="flex flex-col gap-[16px]">
        <h5 className="text-[32px] font-averta font-semibold after:content-['*'] after:text-red-450 after:ml-[4px]">
          The property I want to tour
        </h5>
        <Box className="flex flex-col">
          <input
            className="outline-none w-full text-[32px] font-averta border-[2px] border-slate-200 rounded-[24px] px-[40px] focus:border-default py-[32px]"
            disabled
            {...register('propertyName', {
              required: 'Property name is required',
            })}
            placeholder="Property name"
            style={{ color: isReschedule ? 'rgba(0, 0, 0, 0.38)' : '#050A22' }}
          />
          <FormHelperText
            className="font-averta w-1/2"
            sx={{
              fontSize: 12,
              color: '#E74F4F',
            }}
            id="component-error-text"
          >
            {errors.propertyName?.message}
          </FormHelperText>
        </Box>
        <Box className="flex flex-col gap-[28px] p-[16px] shadow-1.5 rounded-[24px]">
          <Box className="flex gap-[16px]">
            {loading ? (
              <>
                <Skeleton className="w-1/2" />
                <Skeleton className="w-1/2" />
              </>
            ) : (
              <ImageList cols={2} rowHeight={103}>
                {imageList.map((item: ImageType) => (
                  <ImageListItem key={item.id}>
                    <img
                      className="rounded-[16px] overflow-hidden"
                      height={103}
                      src={`${item.picSrcUrl}?w=155&h=103&fit=crop&auto=format`}
                      srcSet={`${item.picSrcUrl}?w=155&h=103&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.alt}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </Box>
          {loading ? (
            <Skeleton />
          ) : (
            <Box className="flex items-center gap-[16px]">
              <AddressIcon color="#A6A9BB" />
              <p className="font-averta text-[28px]">{address}</p>
            </Box>
          )}
        </Box>
      </Box>
      {/* The unit I want to tour */}
      <Box id="choose-tour-unit" className="flex flex-col gap-[16px]">
        <h5 className="text-[32px] font-averta font-semibold">The unit I want to tour</h5>
        <Controller
          name="units"
          render={({ field }) => (
            <Select
              multiple
              id="demo-multiple-name"
              {...field}
              sx={{
                fontSize: 16,
              }}
              MenuProps={{
                style: {
                  maxHeight: 300,
                },
                sx: {
                  '.MuiList-root': {
                    padding: '4px',
                    paddingRight: '4px !important',
                  },
                },
              }}
              renderValue={selected =>
                selected
                  .map((item: string) => {
                    const selectItem = JSON.parse(item)
                    return selectItem.aliasName || selectItem.name
                  })
                  .join(', ')
              }
            >
              {unitList.map((unit: UnitType) => (
                <MenuItem
                  sx={{
                    fontSize: 14,
                    borderRadius: '4px',
                    height: '22px',
                    '&.Mui-selected': {
                      '.MuiListItemText-root .MuiTypography-root': {
                        fontWeight: '600',
                      },
                      '& .MuiListItemIcon-root': {
                        opacity: 1,
                      },
                    },
                    '& .MuiListItemIcon-root': {
                      opacity: 0,
                    },
                  }}
                  key={`unit-${unit.id}`}
                  value={JSON.stringify(unit)}
                >
                  <ListItemText>{unit.aliasName || unit.name}</ListItemText>
                  <ListItemIcon>
                    <CheckOutlined color="#E74F4F" />
                  </ListItemIcon>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
      {/* Choose the way to tour */}
      <Box className="flex flex-col gap-[16px]">
        <h5 className="text-[32px] font-averta font-semibold after:content-['*'] after:text-red-450 after:ml-[4px]">
          Choose the way to tour
        </h5>
        <Box className="flex flex-col">
          <Controller
            name="tourType"
            rules={{ required: 'Tour type is required' }}
            render={({ field }) => (
              <Select {...field} disabled={isReschedule}>
                {tourTypeList.map(tourType => (
                  <MenuItem key={`property-form-tour-type-${tourType.key}`} value={tourType.key}>
                    {tourType.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Box className="hidden">
            <Select value={watchTourType} {...register('tourType')}>
              {tourTypeList.map(tourType => (
                <MenuItem key={`property-form-tour-type-${tourType.key}`} value={tourType.key}>
                  {tourType.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {watchTourType === 'IN_PERSON_TOUR' && (
            <p className="text-[24px] font-averta text-zinc-400">There will be a tour host to guild you with the tour.</p>
          )}

          <FormHelperText
            className="font-averta w-1/2"
            sx={{
              fontSize: 12,
              color: '#E74F4F',
            }}
            id="component-error-text"
          >
            {errors.tourType?.message}
          </FormHelperText>
        </Box>
      </Box>
    </Box>
  )
}

export default PropertyForm
