import React from 'react'
import { useRoutes } from 'react-router-dom'
import Form from '../components/form/versions'
import ChatgptTour from '../components/chatgptTour'
import TripalinkTourV2 from '../components/tripalinkTourV2'
import TripalinkTourV2Detail from '../components/tripalinkTourV2/tourDetail'
import ScheduleTour from '../components/scheduleTour'
import ScheduleTourV2 from '../components/scheduleTourV2'

const getAuthRoutes = () =>
  useRoutes([
    {
      path: '/form',
      element: <Form />,
      children: []
    }, 
    {
      path: '/tripalinkTour',
      element: <ChatgptTour />
    },
    {
      path: '/tripalinkTourV2',
      element: <TripalinkTourV2 />,
      children: []
    },
    {
      path: '/tourDetail/:tourId',
      element: <TripalinkTourV2Detail />
    },
    {
      path: '/scheduleTour/:questionnaireId/:signature/:organizationId/:version/:channel',
      element: <ScheduleTour />
    },
    {
      path: '/scheduleTourV2/:questionnaireId/:signature/:organizationId/:version/:channel',
      element: <ScheduleTourV2 />
    }
  ])

const AuthRoutes = () => getAuthRoutes()
export default AuthRoutes
