import React, { FC, useContext, useEffect, useMemo } from 'react'
import { Typography, Box, Chip } from '@mui/material'
import { CommonModuleProps } from './types.d'
import indexHooks from './hooks/index'

const { floorPlanHooks } = indexHooks

const FloorPlan = ({ currentIndex }: CommonModuleProps) => {
  const { currentData, selectBedroom, selectBathroom, maxSelectBathRoom, bedroomList, bathroomList, onSelectBedroom, onSelectBathroom } =
    floorPlanHooks(currentIndex)
  return (
    <Box className="w-full !px-[40px] !py-[32px] grid grid-cols-1 gap-y-[64px]">
      <Box>
        <Typography className="!font-Averta6 !text-[36px]">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
        <Typography className="!font-Averta4 !text-[28px] !mt-[16px] text-[#75798D]">{currentData?.desc}</Typography>
      </Box>
      {bedroomList?.length ? (
        <Box>
          <Typography className="!font-Averta6 !text-[32px]">Number of bedrooms</Typography>
          <Box className="grid gap-y-[16px] gap-x-[32px] mt-[32px] grid-cols-[repeat(auto-fill,200px)]">
            {bedroomList?.map((item: { key: string; value: string; desc: string; show: boolean }) => (
              <Chip
                onClick={() => onSelectBedroom(item.key)}
                className={`w-[200px] !py-[12px] ${
                  selectBedroom.includes(item?.key) ? '!border-[#050A22] !bg-[#F7F9FC]' : '!border-[#E0E2EF]'
                } !font-Averta4 !text-[28px]`}
                label={item.desc}
                variant="outlined"
                key={item.key}
              />
            ))}
          </Box>
        </Box>
      ) : null}
      {bathroomList?.length ? (
        <Box>
          <Typography className="!font-Averta6 !text-[32px]">Number of bathrooms</Typography>
          <Box className="grid gap-y-[16px] gap-x-[32px] mt-[32px] grid-cols-[repeat(auto-fill,200px)]">
            {bathroomList?.map((item: { key: string; value: string; desc: string; show: boolean }) => (
              <Chip
                disabled={Number(item.key) > Number(maxSelectBathRoom) + 1}
                onClick={() => onSelectBathroom(item.key)}
                className={`w-[200px] !py-[12px] ${
                  selectBathroom.some((val: string) => val === item.key) ? '!border-[#050A22] !bg-[#F7F9FC]' : '!border-[#E0E2EF]'
                } !font-Averta4 !text-[28px]`}
                label={item.desc}
                variant="outlined"
                key={item.key}
              />
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default FloorPlan
