import React, { FC } from 'react'
import { Box, Slider, Typography } from '@mui/material'
import { CommonModuleProps } from './types.d'
import hooksIndex from './hooks/index'

const { budgetHooks } = hooksIndex

const Budget = ({ currentIndex }: CommonModuleProps) => {
  const { currentData, minBudget, maxBudget, sliderChange } = budgetHooks(currentIndex)

  return (
    <>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[32px]">
        <div className="flex justify-center items-center">
          <div className="flex w-2/5 bg-[#F7F9FC] rounded-[24px] py-[10px] px-[32px] justify-between items-center">
            <div className="flex items-start flex-col">
              <span className="text-[#75798D] text-[24px] font-Averta4 mb-[8px]">Min</span>
              <span className="text-[#050A22] text-[32px] font-Averta4">{`$${minBudget?.toLocaleString()}`}</span>
            </div>
          </div>
          <span className="flex-grow text-[#75798D] font-Averta4 text-[32px] text-center">To</span>
          <div className="flex w-2/5 bg-[#F7F9FC] rounded-[24px] py-[10px] px-[32px] justify-between items-center">
            <div className="flex items-start flex-col">
              <span className="text-[#75798D] text-[24px] font-Averta4 mb-[8px]">Max</span>
              <span className="text-[#050A22] text-[32px] font-Averta4">{`$${maxBudget?.toLocaleString()}`}</span>
            </div>
          </div>
        </div>
        <div className="mt-[48px]">
          <Slider
            onChange={sliderChange}
            step={100}
            value={[minBudget, maxBudget]}
            max={currentData?.question?.maxBudget || 0}
            min={currentData?.question?.minBudget || 0}
            color="secondary"
          />
        </div>
      </Box>
    </>
  )
}

export default Budget
