import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, LinearProgress, Typography } from '@mui/material'
import React, { FC, useMemo } from 'react'

interface FooterProps {
  // eslint-disable-next-line react/require-default-props
  loading?: boolean
  onNext: () => void
  onSubmit: () => void
  currentStep: number
  allStep: number
  disabled: boolean
}
const FormV4Footer: FC<FooterProps> = props => {
  const { onNext, onSubmit, currentStep, allStep, disabled, loading = false } = props
  const isLastStep = useMemo(() => currentStep === allStep, [currentStep, allStep])
  const onClick = () => {
    if (disabled) {
      return
    }
    if (isLastStep) {
      onSubmit()
    } else {
      onNext()
    }
  }
  return (
    <Box className="fixed flex justify-between items-center w-full bottom-0 py-[32px] px-[40px] bg-white shadow-footerShadow">
      <Box className="flex flex-col justify-start items-start">
        <span className="text-[36px] font-Averta7">
          {currentStep}/{allStep}
        </span>
        <LinearProgress className="w-[340px] my-[20px]" variant="determinate" value={Number((currentStep * 100) / allStep)} />
      </Box>
      <LoadingButton
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        className="!rounded-[24px] !py-[24px] !px-[80px]"
        variant="contained"
      >
        <Typography className="!text-[32px] font-Averta6 font-semibold  capitalize">{isLastStep ? 'Submit' : 'Next'}</Typography>
      </LoadingButton>
    </Box>
  )
}

export default FormV4Footer
