import React, { useMemo, useContext, useEffect } from 'react'
import contextData from '../contextData'

const ParkingHooks = (currentIndex: number) => {
  const { moduleList, dataCollect, mergeDataCollectFunction } = useContext(contextData)
  const currentData = moduleList[currentIndex]
  const parkingSpot = (useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[0]?.value, [dataCollect]) as string) || ''
  const isCanNext = useMemo(() => Number(parkingSpot) === 0 || Boolean(parkingSpot), [parkingSpot])

  const displayData = useMemo(() => {
    const parkingSpotText = currentData?.question?.parkingSpot?.find((item: { key: string }) => item?.key === parkingSpot)?.desc || ''
    return parkingSpotText
  }, [parkingSpot])

  const onParkingChange = (value: string) => {
    const submitValue = [{ name: dataCollect[currentIndex]?.submitValue?.[0].name, value: value || null }]
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, { submitValue })
    }
  }

  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData
      })
    }
  }, [isCanNext, displayData])

  return {
    currentData,
    parkingSpot,
    onParkingChange
  }
}

export default ParkingHooks
