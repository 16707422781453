import React from 'react'
import PropertyForm from './propertyForm'
import DateForm from './dateForm'
import TourDetail from './tourDetail'

interface Props {
  step: number
  prospectId: string | number
  agent: {
    agentId: string
    agentName: string
  }
}

const stepComponents = [PropertyForm, DateForm, TourDetail]

const StepItemCom = ({ step, agent, prospectId }: Props) => {
  const StepComponent = stepComponents[step]

  return <StepComponent agent={agent} prospectId={prospectId} />
}

export default StepItemCom
