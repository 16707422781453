import React from 'react'
import { Typography, Box } from '@mui/material'
import indexHooks from './hooks/index'
import { CommonModuleProps, QuestionItemProps } from './types.d'

const { parkingHooks } = indexHooks

const Parking = ({ currentIndex }: CommonModuleProps) => {
  const { parkingSpot, onParkingChange, currentData } = parkingHooks(currentIndex)

  return (
    <div>
      <Box className="w-full !px-[40px] !py-[32px]">
        <Typography className="!font-Averta6 !text-[36px]">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-[40px] !py-[32px]">
        <div className="w-full grid gap-y-[32px]">
          {currentData?.question?.parkingSpot?.map((item: QuestionItemProps) => {
            const active = item.key === parkingSpot
            return (
              <Box
                onClick={() => onParkingChange(item.key)}
                className={`${
                  active ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                } flex items-center cursor-pointer justify-start border rounded-[24px] px-[32px] py-[20px] `}
                key={item.key}
              >
                <Box
                  className={`w-[32px] h-[32px] border rounded-[32px] mr-[32px] ${
                    active ? 'border-[#E74F4F] border-[12px]' : 'border-[#E0E2EF] '
                  }`}
                />
                <Typography className="!font-Averta4 !text-[28px] !leading-[48px]">{item.desc}</Typography>
              </Box>
            )
          })}
        </div>
      </Box>
    </div>
  )
}

export default Parking
